import React, { useState, useEffect, useRef, useReducer } from 'react';
import { useHistory } from "react-router-dom";


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import Loading from 'Loading';
import TableToExcel from "@linways/table-to-excel";
const JURUSAN = { "1": "IPA", "2": "IPS", "3": "Keagamaan", "4": "Bahasa" };
const RAYON = { "1": "YK", "2": "B", "3": "GK", "4": "KP", "5": "S" };


const formatNum = (v, dec) => {

    const x = parseFloat(v)
    if (x > 0) {
        return x.toFixed(dec);

    } else {
        return '';
    }
}

const initialState = {

    page: 1,
    query: '',
    filter: [],
    items: [],
    sort: 'no',
    sort_order: 1,
};

function reducer(state, action) {


    switch (action.type) {
        case 'sort':
            return { ...state, sort: action.sort, sort_order: action.sort_order, page: 1 };
        case 'query':
            return { ...state, query: action.payload, page: 1 };
        case 'filter':
            return { ...state, filter: action.payload, page: 1 };
        case 'more':
            return { ...state, page: state.page + 1 };
        case 'clear':
            return { ...state, page: 1, items: [] };
        default:
            throw new Error();
    }
}

const SearchBox = (props) => {
    const [value, setValue] = useState('');
    const timerRef = useRef();
    const lastValueRef = useRef('');

    useEffect(() => {


        if (lastValueRef.current == value) return;


        const id = setTimeout(() => {
            lastValueRef.current = value;
            props.onChange(value)

        }, 500);
        timerRef.current = id;
        return () => {

            clearTimeout(timerRef.current);
        }

    }, [value])
    return <TextField type="search" label="Cari" variant="filled" {...props} value={value} onChange={(e) => { setValue(e.target.value) }}></TextField>
    // return <input type="search" value={value} onChange={(e) => { setValue(e.target.value) }} placeholder="Cari Siswa" style={{ borderRadius: 5, border: 1, padding: "8px", fontSize: "11pt", "flexGrow": 1 }} />
}

export default function Admin(props) {

    console.log(props);

    let history = useHistory();
    const [search, setSearch] = useState();
    const [page, setPage] = useState(1);
    const [items, setItems] = useState([]);
    const [more, setMore] = useState(false);
    const [loading, setLoading] = useState(false);
    const [state, dispatch] = useReducer(reducer, { ...initialState, filter: props.filter, ...props.sort });
    const cancelRef = useRef();

    const tableRef = useRef();


    function doSort(col) {
        let sort_order = state.sort_order;

        let sort = col;
        if (state.sort == col) {
            console.log(state.sort_order, !sort_order);

            sort_order = +(!sort_order);
        }

        dispatch({ type: 'sort', sort, sort_order });


    }

    function load() {

        setLoading(true);
        setMore(true)

        let params = { page: state.page, query: state.query, ...state.filter, sort: state.sort, sort_order: state.sort_order }
        axios.get('/api/admin/u', {
            params: params, cancelToken: new axios.CancelToken((c) => {
                cancelRef.current = c;
            }),
        },)
            .then(function (response) {


                let x;
                if (state.page == 1) {
                    x = response.data;

                } else {

                    x = items.slice();

                    x = x.concat(response.data);

                }

                if (response.data.length < 100) {
                    setMore(false);

                } else {
                    setMore(true);
                }

                setPage(x => x + 1);
                setItems(x);

            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                setLoading(false)
            });

    }

    // useEffect(() => {
    //     load();
    // }, [])

    useEffect(load, [state]);

    // useEffect(() => {

    //     dispatch({ type: 'filter', payload: props.filter });

    // }, [props.filter]);


    return (<>
        <div style={{ margin: 'auto', marginBottom: 15, textAlign: 'right', display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>

            <div>{props.hasil || !loading && <Button variant="contained"
                onClick={() => {
                    TableToExcel.convert(tableRef.current, {
                        name: props.title + '.xlsx',
                    })
                }}
            >Expor ke XLXS</Button>}</div>
            {props.search !== false && <SearchBox style={{ width: 300 }} onChange={(v) => { dispatch({ type: 'query', payload: v }) }} />}
        </div>

        <table width="100%" ref={tableRef} cellPadding={10} cellSpacing={0} className="t_daftar" style={{ display: 'none', textAlign: 'right' }}>
            <thead style={{ backgroundColor: '#eee' }}>
                <tr >
                    <td colSpan={7}>{props.title}</td>
                </tr>
                <tr style={{ textAlign: 'center' }}>
                    <td>
                        No.
                    </td>
                    <td>
                        Kode
                    </td>
                    <td>
                        NISN
                    </td>
                    <td >
                        Nama
                    </td>
                    <td>
                        L/P
                    </td>
                    <td >
                        Asal Sekolah
                    </td>
                    <td>
                        P
                    </td>
                    <td>
                        Nilai
                    </td>
                    <td>
                        No HP
                    </td>
                    <td>
                        Nama ORTU
                    </td>
                    <td>
                        No Hp ORTU
                    </td>

                </tr>
            </thead>
            <tbody>
                {items.map((i, idx) => <tr key={i.nisn} className="hover">
                    <td >{idx + 1}</td>
                    <td style={{ textAlign: 'right' }}>{i.kd}</td>
                    <td style={{ textAlign: 'right' }}>{i.nisn}</td>
                    <td style={{ textAlign: 'left' }}>{i.nama}</td>
                    <td style={{ textAlign: 'left' }}>{i.jk == 1 ? 'L' : 'P'}</td>
                    <td>{i.sekolah}</td>
                    <td data-t="n" >{i.pilihan}</td>
                    <td data-t="n" >{formatNum(i.score, 2)}</td>
                    <td>{i.hp}</td>
                    <td>{i.ortu_nama}</td>
                    <td>{i.ortu_hp}</td>
                </tr>)}
            </tbody>
        </table>
        <table width="100%" cellPadding={10} cellSpacing={0} className="t_daftar" style={{ textAlign: 'right' }}>
            <thead style={{ backgroundColor: '#eee' }}>

                <tr style={{ textAlign: 'center' }}>
                    <td onClick={() => doSort('no')} className="sortable">
                        No. {state.sort == 'no' && (state.sort_order == 1 ? <>&darr;</> : <>&uarr;</>)}
                    </td>
                    <td onClick={() => doSort('nisn')} className="sortable">
                        NISN {state.sort == 'nisn' && (state.sort_order == 1 ? <>&darr;</> : <>&uarr;</>)}
                    </td>
                    <td onClick={() => doSort('nama')} width="100%" className="sortable">
                        Nama / Sekolah {state.sort == 'nama' && (state.sort_order == 1 ? <>&darr;</> : <>&uarr;</>)}
                    </td>
                    {/* {props.lokasi && <td>Lokasi </td>} */}
                    {/* {props.pilihan && <td className="sortable" onClick={() => doSort('pilihan')}>Pilihan {state.sort == 'pilihan' && (state.sort_order == 1 ? <>&darr;</> : <>&uarr;</>)}</td>} */}
                    {/* <td>
                        &#x2714; {state.sort == 'verified' && (state.sort_order == 1 ? <>&darr;</> : <>&uarr;</>)}
                    </td> */}
                    {!props.showHasil && <>
                        {/* <td>
                            a
                        </td> */}
                        {/* <td>
                            Tahfidz
                        </td> */}
                        {/* // <td>
                            c
                        </td> */}

                        {/* <td>
                            Prestasi
                        </td> */}
                    </>}
                    {props.diterima && <td className="sortable" onClick={() => doSort('diterima')}>
                        Diterima {state.sort == 'diterima' && (state.sort_order == 1 ? <>&darr;</> : <>&uarr;</>)}
                    </td>
                    }
                    {/* {props.cadangan && <td>
                    </td>
                    } */}
                    <td className="sortable" onClick={() => doSort('score')}>
                        Nilai {state.sort == 'score' && (state.sort_order == 1 ? <>&darr;</> : <>&uarr;</>)}
                    </td>
                </tr>
            </thead>
            <tbody>
                {items.map((i, idx) => <tr key={i.nisn} className="hover" >
                    <td >{idx + 1}</td>
                    <td style={{ textAlign: 'right' }}>{i.nisn}</td>
                    <td style={{ textAlign: 'left' }}>{i.nama}<br /><span style={{ color: '#808080' }}>{i.sekolah}</span></td>
                    {/* {props.lokasi &&  <td style={{textAlign: 'center'}} >{RAYON[i.rayon]}</td> } */}
                    {/* {props.pilihan && <td style={{ textAlign: 'center' }} >{i.pilihan}</td>} */}
                    {/* <td style={{ textAlign: 'center' }}>{i.verified == 1 && <span style={{ color: 'green' }}>&#x2714;</span>}{i.verified == -1 && <span style={{ color: 'darkred' }}>&#x02717;</span>}</td> */}
                    {!props.showHasil && <>
                        {/* <td >{formatNum(i.a, 0)}</td> */}
                        {/* <td >{formatNum(i.b, 0)}</td> */}
                        {/* <td >{formatNum(i.c, 0)}</td> */}
                        {/* <td >{formatNum(i.d, 0)}</td>
                        <td >{formatNum(i.e, 0)}</td> */}
                        {/* <td >Tahfidz</td>
                        <td >Prestasi</td> */}
                    </>}

                    {props.diterima && <td style={{ whiteSpace: 'no-wrap', textAlign: 'left' }}>
                        <span style={{ whiteSpace: 'nowrap' }}> {i.ma} </span>
                        <br /><span style={{ color: '#808080' }}>{i.maj}</span>
                    </td>
                    }
                    {/* 
                    {props.cadangan && <td style={{ whiteSpace: 'pre', textAlign: 'left', verticalAlign: 'top' }}>
                        <span style={{ whiteSpace: 'pre' }}>{i.cadangan}</span>
                    </td>
                    } */}
                    <td >{formatNum(i.score, 0)}</td>
                    {/* <td >Total</td> */}
                </tr>)}
            </tbody>
        </table>
        <div style={{ margin: 10 }}>
            {loading && <Loading />}
            {!loading && items.length > 0 && more && <Button fullWidth onClick={() => { dispatch({ type: 'more' }) }}>Halaman Berikutnya</Button>}
        </div>

    </>

    )
}