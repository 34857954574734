import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Typography, Container, Snackbar } from "@material-ui/core";
import axios from "axios";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function UbahPassword() {
  const history = useHistory();
  const classes = useStyles();
  const [passwordLama, setPasswordLama] = useState("");
  const [passwordBaru, setPasswordBaru] = useState("");
  const [konfirmasiPassword, setKonfirmasiPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");

    if (!isLoggedIn) {
      history.push("/login");
      return;
    }
  }, [history]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Validasi bahwa password baru sama dengan konfirmasi password
    if (passwordBaru !== konfirmasiPassword) {
      setSnackbarMessage("Password baru tidak cocok dengan konfirmasi password");
      setSnackbarOpen(true);
      return;
    }

    // Panggil API untuk mengubah password
    axios
      .post(`/api/ubahpassword/${localStorage.getItem("sekolah_id")}`, {
        password_lama: passwordLama,
        password_baru: passwordBaru,
        konfirmasi_password: konfirmasiPassword,
      })
      .then((response) => {
        // Jika berhasil, tampilkan pesan sukses
        setSnackbarMessage("Password berhasil diubah");
        setSnackbarOpen(true);
        handleLogout();
      })
      .catch((error) => {
        // Tangani kesalahan jika terjadi
        console.error("Gagal mengubah password:", error);
        // Tampilkan pesan kesalahan
        setSnackbarMessage("Gagal mengubah password");
        setSnackbarOpen(true);
      });
  };
  
  const handleLogout = () => {
    axios
      .post("/api/logout")
      .then((response) => {
        // Redirect ke halaman login setelah logout berhasil
        localStorage.removeItem("isLoggedIn");
        // Hapus item 'sekolah_id' dari localStorage
        localStorage.removeItem("sekolah_id");
        window.location.reload();
      })
      .catch((error) => {
        // Tangani kesalahan jika diperlukan
        console.error("Gagal logout:", error);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.formContainer}>
        <Typography component="h1" variant="h5">
          Ubah Password
        </Typography>
        <form className={classes.form} onSubmit={handleFormSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password Lama"
            type="password"
            value={passwordLama}
            onChange={(e) => setPasswordLama(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password Baru"
            type="password"
            value={passwordBaru}
            onChange={(e) => setPasswordBaru(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Konfirmasi Password"
            type="password"
            value={konfirmasiPassword}
            onChange={(e) => setKonfirmasiPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submitButton}
          >
            SIMPAN
          </Button>
        </form>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Container>
  );
}

export default UbahPassword;
