import React, { useEffect, useState } from "react";
import banner from "./img/pengumuman.png";
import { Form, InputGroup, Table } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import "./school.css";
import { useParams, useLocation } from "react-router-dom";
import Axios from "axios";

function School() {
  const [schoolData, setSchoolData] = useState([]);
  const { id } = useParams();
  const [data, setData] = useState({
    FINAL: window.FINAL,
  });
  const [search, setSearch] = useState("");
  const location = useLocation();
  const schoolName = location.state ? location.state.schoolName : "";

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await Axios.get(`/api/admin/u?source=final&sid=${id}`);
        setSchoolData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [id]);

  const filteredSchoolData = schoolData.filter((student) => {
    for (const key in student) {
      if (
        student[key] &&
        student[key].toString().toLowerCase().includes(search.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });

  return (
    <>
      <div style={{ position: "relative", width: "100%", height: "auto" }}>
        <img className="banner-school" src={banner} alt="banner" />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: "white",
            width: "80%",
            maxWidth: "500px",
          }}
        >
          <p className="title-school">{schoolName}</p>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <InputGroup
          className="mb-3"
          style={{
            width: "60%",
            paddingTop: "30px",
          }}
        >
          <InputGroup.Text
            id="basic-addon1"
            style={{ borderRadius: "90px", backgroundColor: "#f0f0f0" }}
          >
            <FaSearch />
          </InputGroup.Text>
          <Form.Control
            placeholder="Cari Sekolah"
            aria-label="Cari Sekolah"
            aria-describedby="basic-addon1"
            style={{ borderRadius: "90px", backgroundColor: "#f0f0f0" }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </InputGroup>
      </div>

      {data.FINAL === 1 ? (
        <div style={{ padding: "10px 20px" }}>
          <Table bordered hover responsive>
            <thead>
              <tr>
                <th className="col-1 text-center">No</th>
                <th className="col-4 text-center">Nama</th>
                <th className="col-3 text-center">Asal Sekolah</th>
                <th className="col-2 text-center">Keterangan</th>
              </tr>
            </thead>
            <tbody>
              {filteredSchoolData.map((student, index) => (
                <tr key={index}>
                  <td className="text-center align-middle">{index + 1}</td>
                  <td className="text-center align-middle">{student.nama}</td>
                  <td className="text-center align-middle">
                    {student.sekolah}
                  </td>
                  <td className="text-center align-middle">
                    {student.verified == 1
                      ? "Diterima"
                      : student.verified == -1
                      ? "Tidak Diterima"
                      : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          PENGUMUMAN FINAL BELUM DIBUKA
        </div>
      )}
      <div style={{ margin: 50 }}></div>
    </>
  );
}

export default School;
