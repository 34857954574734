import React, { useState, useEffect } from 'react';

import { Link } from "react-router-dom";
import Container from '@material-ui/core/Container';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';

const JURUSAN = { "1": "", "2": "", "3": "", "4": "" };
const RAYON = { "1": "Kota Yogyakarta", "2": "Kabupaten Bantul", "3": "Kabupaten Gunungkidul", "4": "Kabupaten Kulon Progo", "5": "Kabupaten Sleman" };

export default function Admin() {


    const [summary, setSummary] = useState({});
    const [summaryJurusan, setSummaryJurusan] = useState({});
    const [schools, setSchools] = useState({});

    const [search, setSearch] = useState('');
    function getSchools() {


        axios.get('/api/school2')
            .then(function (response) {
                setSchools(response.data)

            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });

    }
    const getSummary = () => {


        axios.get('/api/admin/hasil/dashboard')
            .then(function (response) {
                let x = response.data;
                setSummary(x);
            })
            .catch(function (error) {
                // handle error
                // console.log(error);
            })
            .then(function () {
                // always executed
            });


    };

    const getJurusanSummary = () => {


        axios.get('/api/admin/hasil/jurusan')
            .then(function (response) {
                let x = response.data;

                const y = x.reduce((obj, item) => {


                    const key = item.sid + '_' + item.jid

                    return {
                        ...obj,
                        [key]: item,
                    };
                }, {});


                setSummaryJurusan(y);
            })

            .catch(function (error) {
                // handle error
                // console.log(error);
            })
            .then(function () {
                // always executed
            });


    };

    useEffect(() => {
        getSummary();
        getJurusanSummary();
        getSchools();


    }, [])

    return (

        <div className="">

            <div style={{ textAlign: 'center', background: '#eee', padding: 10 }} >
                <div className="container">
                    <p style={{ fontSize: '32px', fontWeight: 'bold' }}>PORTAL</p>
                    <p style={{ fontSize: '24px' }}>Hasil Seleksi Tahap I PPDB JPTT 2024</p>
                </div>
            </div>
            <div style={{ textAlign: 'center', padding: 10 }}>
                <p style={{ fontWeight: 'bold' }}>Pembaruan Terakhir :  <span style={{ padding: 8, backgroundColor: '#42ff00', borderRadius: '10px' }}>{summary.t} </span></p></div>

            <div style={{ margin: 50 }}></div>
            <Container style={{ minHeight: '100vh' }}>
                {/* <Grid container spacing={3} style={{ textAlign: 'center' }}>
                    <Grid item xs={12} sm={4}>

                        <Link to='/ppdb-admin/u' >
                            <h2 style={{ color: '#1E6887' }}>Pendaftar</h2>

                            <h1>{summary.p}</h1>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ borderLeft: "1px dashed #c0c0c0", borderRight: "1px dashed #c0c0c0" }}>
                        <Link to='/ppdb-admin/u/?filter=success' >
                            <h2 style={{ color: '#1E6887' }}>Diterima</h2>
                            <h1>{summary.h}</h1>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={4} >
                        <Link to='/ppdb-admin/u/?filter=failed' >
                            <h2 style={{ color: '#1E6887' }}>Tidak Diterima</h2>
                            <h1>{summary.f}</h1>
                        </Link>
                    </Grid>
                </Grid> */}

                <div style={{ width: '70%', margin: 'auto', marginTop: 50, marginBottom: 50, textAlign: 'center', }}>

                    <TextField inputProps={{ placeholder: 'Cari Sekolah', type: 'search' }} value={search} onChange={(e) => setSearch(e.target.value)} variant="outlined" fullWidth />

                </div>
                {Object.keys(schools).map(k => {

                    const items = schools[k].filter((s) => { return (s.n.indexOf(search.toUpperCase()) > -1) });

                    if (items.length === 0) {
                        return null
                    }
                    return <React.Fragment key={k}><p style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginTop: 50, marginBottom: 50 }}>{RAYON[k]}</p>

                        <Grid container spacing={3} style={{ textAlign: 'center' }} justify="center" alignItems="stretch">
                            {items.map(s => {

                                const jurusan = Object.keys(s.j);

                                return <Grid key={s.id} item xs={12} sm={6} md={4} lg={3} style={{ display: 'flex' }}
                                >
                                    <div style={{ flex: 1, border: '1px solid #eee' }}>
                                        <p style={{ fontWeight: 'normal', fontSize: '18px', color: '#1E6887', padding: 10, backgroundColor: '#eee', margin: 0 }}><Link to={`./s/${s.id}/`} >{s.n}</Link></p>
                                        <table width="100%" cellPadding={10} style={{ border: 0 }}>
                                            <thead style={{ backgroundColor: '#eee' }}>
                                                <tr>
                                                    {/* <td>Jurusan</td> */}
                                                    <td>Kuota</td>
                                                    <td>Pendaftar</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {jurusan.map(j => {
                                                    return <tr key={j}>
                                                        {/* <td>{JURUSAN[j]}</td> */}
                                                        <td>{s.j[j]}</td>
                                                        <td>{summaryJurusan[s.id + '_' + j] && summaryJurusan[s.id + '_' + j].total}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>

                                </Grid>
                            }


                            )}
                        </Grid>
                    </React.Fragment>
                })}
            </Container>


        </div >

    )
}