import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Exposure } from '@material-ui/icons';

function Result(props) {

	const [search, setSearch] = useState('');

	return (
		<div className="">

			<div className="no-print" style={{ textAlign: 'center', background: '#eee', padding: 10 }} >
				<div className="container">

					<h1>Periksa Hasil</h1>

				</div>
			</div>
			<div style={{ textAlign: 'center', marginTop: 50, }} >Masukkan NISN</div>
			<div style={{ width: 300, margin: 'auto', marginBottom: 50, textAlign: 'center', }}>

				<TextField inputProps={{ type: 'search', style: { textAlign: 'center', fontSize: '150%', fontFamily: 'monospace', fontWeight: 'bold' } }} value={search} onChange={(e) => setSearch(e.target.value.toUpperCase())} variant="outlined" fullWidth />
			</div>
			<div className="no-print" style={{ textAlign: 'center' }}>
				<Button onClick={() => { props.history.replace('/hasil/' + search); }} variant="contained" color="primary">Periksa Hasil</Button>
			</div>
		</div>
	);
}
export default Result;
