import React, { useState } from 'react';

import fileIcon from './img/file.png';


import filesize from 'filesize';
import axios from 'axios';
import Dropzone from 'react-dropzone';

import AttachFileIcon from '@material-ui/icons/AttachFile';

export default function Upload(props) {

	const [submitProgress, setProgress] = useState(false);
	const [file, setFile] = useState();

	
	const processFileUpload = (fs) => {

		if (fs[0]) {
			const f = fs[0]
			let payload = new FormData();
			payload.set('f', f)
			setFile(false)

			axios.post('/api/upload', payload, {
				timeout: 0,
				onUploadProgress: (pe) => {
	
					if (pe.lengthComputable) {
						let progressTotal = pe.total;
						let progressCurrent = pe.loaded;
						let progress = Math.round((progressCurrent / progressTotal) * 100);
	
						setProgress({ progress, progressCurrent, progressTotal })
					} else {
						setProgress(false)
					}
	
				}
			}).then(function (response) {
	
				setFile({name: f.name, size: f.size, url:response.data.url })
				props.onChange(response.data.url);
			
			})
				.catch(function (error) {
					// handle error
	
					console.error(error);
	
					if (error.response) {
	
						alert('Gagal mengunggah berkas. Harap coba beberapa saat lagi.');
						
					}
	
	
					
				})
				.then(function () {
					setProgress(false)
				});
	
	
		}

	};


	const accept = (props.imageOnly)? 'image/jpeg, image/png' :'image/jpeg, image/png, .docx, .pdf, .zip';
	const acceptText = (props.imageOnly)? 'JPG/PNG':'PDF/ZIP/DOCX/JPG';

	const re = /\.(jpeg|jpg|png)$/;
	let photoFile =  <img src={fileIcon} style={{width: 50}} alt="" />
	if (file && re.test(file.name)){
		photoFile = <div style={{width: 75, height: 75, borderRadius: '100%', margin: 'auto', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', border: '1px solid #c0c0c0', backgroundImage: `url(${file.url})`, backgroundSize:'cover'}}></div>

	}
	return (

		<Dropzone accept={accept} maxSize={10 * 1024 * 1024} multiple={false} onDrop={f => { processFileUpload(f) }}>
			{({ getRootProps, getInputProps }) =>
				<div {...getRootProps({
				})} style={{ height: 150, border: '3px dashed #c0c0c0', overflow: 'hidden', borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<input {...getInputProps()} />
					<div style={{ color: '#c0c0c0', textAlign: 'center', width: '100%', margin: 5 }} >

						{submitProgress && <div>

							{(submitProgress.progress === 100) && <><div className="spinner"><div className="bounce1"></div><div className="bounce2"></div><div className="bounce3"></div></div><div>Memproses...</div></>}
							{(submitProgress.progress < 100) && <>
								<div>Mengunggah...</div>
							<div style={{ width: '90%', position: 'relative', margin: 10, height: 10, borderRadius: 10, border: '1px solid #1E6887' }}>
								<div style={{ width: `${submitProgress.progress}%`, borderRadius: 10, height: 10, background: 'rgb(6, 109, 153)' }}></div>
							</div>
								<div>{`${filesize(submitProgress.progressCurrent)} / ${filesize(submitProgress.progressTotal)}`} ( {`${submitProgress.progress}%`} )</div>
							</>}
						</div>}


						{!file  && !submitProgress && <>
							<div style={{ fontSize: '48pt' }}><AttachFileIcon style={{fontSize: 'inherit'}} /> </div>
							<div> {acceptText}</div>
							<div></div>
						</>}

						{file && <>
							<div style={{ fontSize: '48pt' }}>{photoFile}</div>
							<div>{file.name}</div>
							<div onClick={(e) => {  e.stopPropagation(); props.onChange(''); setFile(false)}}>({filesize(file.size)}) <span style={{ color: 'red', cursor: 'pointer', fontSize: '150%' }}> &#215;</span></div>
						</>}
					</div>
				</div>
			}</Dropzone>


	);
}

