import React, { useState, useEffect, useRef } from 'react';

import { Link, useHistory } from "react-router-dom";



import Container from '@material-ui/core/Container';

import Grid from '@material-ui/core/Grid';


import Button from '@material-ui/core/Button';





export default function Enroll(props) {


	return (
		<div className="">



			<div style={{ textAlign: 'center', background: '#eee', padding: 10 }} >
				<div className="container">

					<h1>Pendaftaran </h1>

				</div>
			</div>
			<Container style={{ minHeight: '50vh', textAlign: 'center', padding: 16, marginTop: 50 }}>

				<Grid container spacing={3} alignContent="center" alignItem="center" justify="center"	>
					<Grid item xs={12} sm={6} md={4}>
						<Button component={Link} to="/daftar/individu/" style={{ padding: 16 }} variant="outlined" fullWidth color="primary" size="large" >Individu</Button>
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<Button component={Link} to="/daftar/kolektif/" style={{ padding: 16 }} variant="outlined" fullWidth color="primary" size="large" >Kolektif</Button>
					</Grid>

				</Grid>
			</Container>



		</div>


	);
}

