import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ListSubheader from '@material-ui/core/ListSubheader';
import filesize from 'filesize';
import { FormControlLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Upload from './Upload';
import "./enroll.css";
function validateNilai(nilai) {
	if (nilai == '') return true;
	const re = /^[1]?[0-9]{1,2}([\,\.][0-9]*)?$/;
	return re.test(String(nilai).toLowerCase());
}
function validatePhone(email) {
	const re = /^[0-9]+$/;
	return re.test(String(email).toLowerCase());
}
function validateEmail(email) {
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}
const jurusan = { "1": "IPA", "2": "IPS", "3": "Keagamaan", "4": "Bahasa" };
const RAYON = { "1": "Kota Yogyakarta", "2": "Kabupaten Bantul", "3": "Kabupaten Gunungkidul", "4": "Kabupaten Kulon Progo", "5": "Kabupaten Sleman" };

export default function Enroll(props) {

	let history = useHistory();
	// const recaptchaRef = useRef(null);

	const [schools, setSchools] = useState([]);
	const [submitProgress, setProgress] = useState(false);
	const [smps, setSmps] = useState([]);
	const [token, setToken] = useState(null);
	const [agree, setAgree] = useState(false);
	const [smp, setSmp] = useState(null);
	const [checked, setChecked] = useState(false);
	const [checkedItems, setCheckedItems] = useState(Array(30).fill(false));

	const handleCheckboxChange = (index) => {
		const newCheckedItems = [...checkedItems];
		newCheckedItems[index] = !newCheckedItems[index];
		setCheckedItems(newCheckedItems);

		// Mengupdate nilai p_tahfidz dengan jumlah checkbox yang dicentang
		const count = newCheckedItems.filter((item) => item === true).length;
		setData({ ...data, p_tahfidz: count });
	};

	const [data, setData] = useState({
		// rayon: '',
		// rayon_label: '',


		pilihan_1: '',
		pilihan_2: '',
		// pilihan_3: '',
		// pilihan_4: '',

		// jurusan_1: '',
		// jurusan_2: '',
		// jurusan_3: '',
		// jurusan_4: '',

		nisn: '',
		nama: '',
		hp: '',
		email: '',

		sekolah: '',
		sekolah_lulus: '2024',
		sekolah_npsn: '',
		sekolah_nama: '',

		// n_un_mat: '',
		// n_un_indo: '',
		// n_un_ipa: '',

		n_mat: '',
		n_indo: '',
		n_ing: '',
		n_ipa: '',

		// n_quran: '',
		// n_fikih: '',
		// n_ski: '',


		jk: '',
		tanggal_lahir: '2005-01-01',
		tempat_lahir: '',


		p_tingkat: '',
		p_juara: '',
		p_nama_kejuaraan: '',


		p_tahfidz: '',
		// p_doa: '',


		ortu_nama: '',
		ortu_hp: '',
		ortu_alamat: '',

		file_photo: null,
		file_skl: null,
		file_un: null,
		// file_prestasi: null,
		// file_tahfiz: null,
		// file_shuambn: null,




	})
	function getToken(token) {
		axios.post('/api/token', { token: token })
			.then(function (response) {
				setToken(response.data.token);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
				alert('Validasi Gagal, tunggu beberapa saat lagi')
				setAgree(false);
				// recaptchaRef.current.reset();
			})
			.then(function () {
				// always executed
			});
	}

	function getSchools() {
		axios.get('/api/school')
			.then(function (response) {
				setSchools(response.data)
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			})
			.then(function () {
				// always executed
			});
	}

	function getSmp() {


		axios.get('/api/smp')
			.then(function (response) {
				let x = response.data;


				setSmps(x);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			})
			.then(function () {
				// always executed
			});

	}

	useEffect(() => {
		getSchools();
		getSmp();


	}, [])

	const doSubmit = () => {
		let payload = new FormData();

		payload.set('token', token);

		for (let [key, value] of Object.entries(data)) {
			if (key.indexOf('_label') > 0) continue;
			payload.set(key, value);
		}

		// Menambahkan informasi checkbox yang dicentang ke dalam payload
		checkedItems.forEach((isChecked, index) => {
			if (isChecked) {
				payload.set(`juz_${index + 1}`, 'checked');
			}
		});

		axios.post('/api/submit', payload, {
			timeout: 0,
			onUploadProgress: (pe) => {
				if (pe.lengthComputable) {
					let progressTotal = pe.total;
					let progressCurrent = pe.loaded;
					let progress = Math.round((progressCurrent / progressTotal) * 100);
					setProgress({ progress, progressCurrent, progressTotal })
				} else {
					setProgress(false)
				}
			}
		}).then(function (response) {
			history.replace('/hasil/' + response.data.id, { 'success': true });
		})
			.catch(function (error) {
				// handle error

				// console.error(error);

				if (error.response) {
					if (error.response.status == 403) {
						alert('Gagal menyimpan. Sesi telah habis. Harap coba beberapa saat lagi');
					} else if (error.response.status == 409) {
						alert('Gagal menyimpan. No NISN sudah terdaftar. Harap memerika isian dan menhubungi panitia bila ini merupakan kesalahan.');
					} else {
						alert('Gagal menyimpan. Harap coba beberapa saat lagi.');
					}
				}
			})
			.then(function () {
				setToken('');
				setAgree(false);
				setProgress(false)
			});
	}

	const onChangeRayon = (e, v) => {


		const name = e.target.name;
		const value = e.target.value;

		let x = { ...data, [name]: value, [name + '_label']: v.props.children, }

		x['pilihan_1'] = '';
		x['jurusan_1'] = '';
		x['pilihan_2'] = '';
		x['jurusan_2'] = '';
		x['pilihan_3'] = '';
		x['jurusan_3'] = '';
		x['pilihan_4'] = '';
		x['jurusan_4'] = '';
		// console.log(x);
		setData(x);

	}
	const onChangeSelectSchool = (e, v) => {


		const name = e.target.name;
		const value = e.target.value;

		let x = { ...data }
		if (value) {
			x[name] = value;
			x[name + '_label'] = v.props.children.props.children;
		}


		if (name === 'pilihan_1') {

			x['pilihan_2'] = '';
			// x['pilihan_3'] = '';
		}

		// if (name === 'pilihan_2') {
		// 	x['pilihan_3'] = '';
		// }

		if (value) {
			let x = { ...data, [name]: value, [name + '_label']: v.props.children.props.children, }
		}

		// console.log(x);
		setData(x);

	}
	const onChangeSelect = (e, v) => {


		const name = e.target.name;
		const value = e.target.value;

		let x = { ...data, [name]: value }

		// console.log(x);
		setData(x);

	}

	const onChange = (e, v) => {


		const name = e.target.name;
		let value = e.target.value;


		if (name === 'nisn') {
			value = value.substring(0, 10);
		}
		if (name === 'sekolah_npsn') {
			value = value.substring(0, 8);
		}
		let x = { ...data, [name]: value }


		setData(x);

	}

	const errors = {};


	const getStep = () => {

		let step = 10;
		// if (!data.rayon) {

		// 	return step;
		// }


		step = 11;
		if (!data.pilihan_1) {
			step = 12;
			return step;
		}
		// if (!data.pilihan_2) {
		// 	step = 21;
		// 	return step;
		// }
		// if (!data.pilihan_3) {
		// 	step = 30;
		// 	return step;
		// }

		// step = 21;

		// if (!data.jurusan) {
		// 	return step;
		// }

		step = 31


		if ((() => {

			let result = true;
			if (!data.nisn) {
				result = false;
			} else {
				if (!validatePhone(data.nisn)) {
					errors['nisn'] = 'Nomor tidak valid';
					result = false;
				}

				if (data.nisn.length < 10) {
					result = false;
				}

				if (data.nisn.length > 10) {
					errors['nisn'] = 'Nomor tidak valid';
					result = false;
				}
			}

			if (!data.nama) {

				result = false;
			}

			if (!data.jk) {

				result = false;
			}

			if (!data.tempat_lahir) {

				result = false;
			}

			if (!data.tanggal_lahir) {

				result = false;
			}
			if (!data.sekolah_lulus) {

				result = false;
			}

			if (!data.hp) {

				result = false;
			} else {

				if (!validatePhone(data.hp)) {
					errors['hp'] = 'Nomor tidak valid';
					result = false;
				}
			}

			// if (!data.email) {

			// 	result = false;
			// } else {

			// 	if (!validateEmail(data.email)) {
			// 		errors['email'] = 'Email tidak valid';
			// 		result = false;
			// 	}
			// }



			if (!data.sekolah) {

				result = false;
			}


			if (data.sekolah === -1) {
				if (!data.sekolah_nama) {
					errors['sekolah_nama'] = 'Wajib Diisi';
					result = false;
				}

				if (!data.sekolah_npsn) {

					errors['sekolah_npsn'] = 'Wajib Diisi';
					result = false;
				} else {
					if (data.sekolah_npsn.length !== 8) {
						result = false;
					}
				}


			}


			return result;




		})()) {


			step = 41
		} else {
			return step;
		}

		// if (!data.p_tahfidz) {
		// 	return step;
		// }

		// if (!data.p_doa) {
		// 	return step;
		// }


		// if (!data.file_tahfiz && !data.file_prestasi) {
		// 	return step;
		// }
		if (!data.p_tahfidz || !data.file_tahfiz) {
			return step;
		}

		step = 51
		if (!data.file_photo || !data.file_skl || !data.file_un) {
			return step;
		}


		// if (!data.file_prestasi) {
		// 	return step;
		// }


		// if (data.file_prestasi) {
		// 	return step;
		// }
		// if (!data.p_juara) {
		// 	return step;
		// }

		// if (!data.p_tingkat) {
		// 	return step;
		// }

		// if (!data.p_nama_kejuaraan) {
		// 	return step;
		// }

		// if (!data.file_prestasi) {
		// 	return step;
		// }
		// } else {

		// 	return step;
		// }

		// if ((() => {
		// 	let result = true;
		// 	if (!data.n_indo) {
		// 		result = false;
		// 	} else {
		// 		if (!validateNilai(data.n_indo)) {
		// 			errors['n_indo'] = 'Nilai tidak valid';
		// 			result = false;
		// 		} else {
		// 			if (parseInt(data.n_indo) < 10) {
		// 				errors['n_indo'] = 'Nilai tidak valid';
		// 				result = false;
		// 			}
		// 			if (parseInt(data.n_indo) > 100) {
		// 				errors['n_indo'] = 'Nilai tidak valid';
		// 				result = false;
		// 			}
		// 		}
		// 	}
		// 	if (!data.n_mat) {
		// 		result = false;
		// 	} else {
		// 		if (!validateNilai(data.n_mat)) {
		// 			errors['n_mat'] = 'Nilai tidak valid';
		// 			result = false;
		// 		} else {
		// 			if (parseInt(data.n_mat) < 10) {
		// 				errors['n_mat'] = 'Nilai tidak valid';
		// 				result = false;
		// 			}
		// 			if (parseInt(data.n_mat) > 100) {
		// 				errors['n_mat'] = 'Nilai tidak valid';
		// 				result = false;
		// 			}
		// 		}
		// 	}
		// 	if (!data.n_ipa) {
		// 		result = false;
		// 	} else {
		// 		if (!validateNilai(data.n_ipa)) {
		// 			errors['n_ipa'] = 'Nilai tidak valid';
		// 			result = false;
		// 		} else {
		// 			if (parseInt(data.n_ipa) < 10) {
		// 				errors['n_ipa'] = 'Nilai tidak valid';
		// 				result = false;
		// 			}
		// 			if (parseInt(data.n_ipa) > 100) {
		// 				errors['n_ipa'] = 'Nilai tidak valid';
		// 				result = false;
		// 			}
		// 		}
		// 	}
		// 	if (!data.n_ing) {
		// 		result = false;
		// 	} else {
		// 		if (!validateNilai(data.n_ing)) {
		// 			errors['n_ing'] = 'Nilai tidak valid';
		// 			result = false;
		// 		} else {
		// 			if (parseInt(data.n_ing) < 10) {
		// 				errors['n_ing'] = 'Nilai tidak valid';
		// 				result = false;
		// 			}
		// 			if (parseInt(data.n_ing) > 100) {
		// 				errors['n_ing'] = 'Nilai tidak valid';
		// 				result = false;
		// 			}
		// 		}
		// 	}

		// 	return result;

		// })()) {
		// 	step = 81;
		// } else {
		// 	return step;
		// }

		// if (!data.n_mat) {
		// 	return step
		// }
		// if (!data.n_indo) {
		// 	return step
		// }
		// if (!data.n_ipa) {
		// 	return step
		// }
		// if (!data.n_ing) {
		// 	return step
		// }

		// for (let [key, value] of Object.entries(data)) {
		// 	if (key.indexOf('n_') == 0) {

		// 		if ((value.length < 2)) {
		// 			continue;
		// 		}

		// 		if (!validateNilai(value)) {
		// 			errors[key] = 'Nilai tidak valid';
		// 		} else {
		// 			if (parseInt(value) < 10) {
		// 				errors[key] = 'Nilai tidak valid. ';

		// 			}
		// 			if (parseInt(value) > 100) {
		// 				errors[key] = 'Nilai tidak valid';
		// 			}
		// 		}
		// 	}
		// }

		step = 71;



		if (!data.ortu_nama) {

			return step;
		}

		if (!data.ortu_hp) {
			return step;
		}

		if (!data.ortu_alamat) {
			return step;
		}

		// step = 81;

		// if (data.rayon != 3 && data.rayon != 4) {
		// 	if (!data.file_photo) {
		// 		return step;
		// 	}

		// 	if (!data.file_skl) {
		// 		return step;
		// 	}

		// 	if (!data.file_un) {
		// 		return step;
		// 	}

		// }

		step = 100;

		return step;





	};




	let schs = schools;
	let schs_map = {};

	schs.forEach(e => { schs_map[e.id] = e });



	const step = getStep();

	let region = 0;
	let region2 = 0;
	let region3 = 0;
	return (
		<div className="">



			<div style={{ textAlign: 'center', background: '#eee', padding: 10 }} >
				<div className="container">

					<h1>Formulir Pendaftaran </h1>

				</div>
			</div>
			<Container style={{ minHeight: '50vh' }}>
				<div style={{ marginTop: 20 }}></div>
				{/* <div style={{ display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', background: '#1E6887', borderRadius: '100%', width: 24, color: 'white', fontWeight: 'bold', textAlign: 'center', height: 24 }}>1</div>
					<div style={{ flex: 1 }}>
						<Typography variant="h6" style={{ color: '#1E6887' }}>Jenis Pendaftaran</Typography>
					</div>
				</div>
				<div className="enroll-block">
					Pendaftaran Individu

				</div> */}

				{/* <div style={{ display: 'flex', alignItems: 'center' }}><div style={{ marginRight: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', background: '#1E6887', borderRadius: '100%', width: 24, color: 'white', fontWeight: 'bold', textAlign: 'center', height: 24 }}>1</div>
					<div style={{ flex: 1 }}>
						<Typography variant="h6" style={{ color: '#1E6887' }}>Lokasi Madrasah</Typography>
					</div>
				</div>
				<div className="enroll-block">
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<FormControl variant="filled" fullWidth   >
								{!data.rayon && <InputLabel >Pilih Kota/Kabupaten</InputLabel>}
								{data.rayon && <InputLabel >Kota/Kabupaten</InputLabel>}
								<Select
									variant="filled"
									fullWidth
									onChange={onChangeRayon}
									value={data.rayon}
									inputProps={{ name: 'rayon' }}
								>

									<MenuItem value={2}>Bantul</MenuItem>
									<MenuItem value={3}>Gunungkidul</MenuItem>
									<MenuItem value={4}>Kulon Progo</MenuItem>
									<MenuItem value={5}>Sleman</MenuItem>
									<MenuItem value={1}>Yogyakarta</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</Grid>

				</div> */}

				{step > 10 && <>

					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ marginRight: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', background: '#1E6887', borderRadius: '100%', width: 24, color: 'white', fontWeight: 'bold', textAlign: 'center', height: 24 }}>1</div>
						<div>
							<Typography variant="h6" style={{ color: '#1E6887' }}>Pilihan Madrasah</Typography>
						</div>
					</div>
					<div className="enroll-block">
						{schs.length === 0 && <div className="spinner"><div className="bounce1"></div><div className="bounce2"></div><div className="bounce3"></div></div>}
						{schs.length > 0 && <>
							<table width="100%" style={{ borderCollapse: 'separate', borderSpacing: '0 25px', marginLeft: -10 }}>
								<tbody>
									<tr>
										<td style={{ verticalAlign: 'top' }}><div style={{ marginRight: 10, alignItems: 'center', display: 'flex', marginTop: 14, justifyContent: 'center', background: '#ccc', borderRadius: '100%', width: 24, color: 'black', fontWeight: 'bold', textAlign: 'center', height: 24 }}>1</div></td>
										<td width="100%">

											<Grid container spacing={3}>

												<Grid item xs={12} sm={8}>

													<FormControl variant="filled" fullWidth   >
														<InputLabel >Pilihan 1*</InputLabel>
														<Select
															onChange={onChangeSelectSchool}
															value={data.pilihan_1}
															inputProps={{ name: 'pilihan_1' }}
															renderValue={(v) => schs_map[v].n}
														>
															{schs.map((i) => {
																if (region != i.region) {
																	region = i.region;
																	return [
																		<ListSubheader key={'rayon-' + i.region}>{RAYON[i.region]}</ListSubheader>
																		, <MenuItem key={i.id} value={i.id} style={{ display: 'block' }}>
																			<div>{i.n}</div>
																		</MenuItem>
																	]
																} else {
																	return <MenuItem key={i.id} value={i.id} style={{ display: 'block' }}>
																		<div>{i.n}</div>
																	</MenuItem>
																}
															}
															)}

														</Select>
													</FormControl>
												</Grid>
												{/* <Grid item xs={12} sm={4} lg={4}>
													{data.pilihan_1 && <FormControl variant="filled" fullWidth  >
														<InputLabel > Jurusan</InputLabel>
														<Select
															onChange={onChangeSelect}
															// value={data.jurusan_1}
															value={data.jurusan_1}
															inputProps={{ name: 'jurusan_1' }}
														>
															{schs_map[data.pilihan_1].d.split(',').map(e => <MenuItem key={e} value={e} style={{ display: 'block' }}>{jurusan[e]}</MenuItem>)}
														</Select>
													</FormControl>}
													<TextField
														error={(errors['jurusan'])}
														name="jurusan"
														onChange={onChange}
														value="X (Umum)"
														fullWidth
														variant="filled"
														label="Jurusan"
													/>
												</Grid> */}
											</Grid>
										</td>
									</tr>
									{data.pilihan_1 && <tr>
										<td style={{ verticalAlign: 'top' }}>
											<div style={{ marginRight: 10, alignItems: 'center', display: 'flex', marginTop: 14, justifyContent: 'center', background: '#ccc', borderRadius: '100%', width: 24, color: 'black', fontWeight: 'bold', textAlign: 'center', height: 24 }}>
												2
											</div>
										</td>
										<td width="100%">

											<Grid container spacing={3}>

												<Grid item xs={12} sm={8}>

													<FormControl variant="filled" fullWidth   >
														<InputLabel >Pilihan 2</InputLabel>
														<Select
															onChange={onChangeSelectSchool}
															value={data.pilihan_2}
															inputProps={{ name: 'pilihan_2' }}
															renderValue={(v) => schs_map[v].n}
														>
															{schs.map((i) => {
																if (i.id == data.pilihan_1) {
																	return null;
																}
																if (region2 != i.region) {
																	region2 = i.region;
																	return [
																		<ListSubheader key={'rayon-' + i.region}>{RAYON[i.region]}</ListSubheader>

																		, <MenuItem key={i.id} value={i.id} style={{ display: 'block' }}>

																			<div>{i.n}</div>


																		</MenuItem>
																	]
																} else {

																	return <MenuItem key={i.id} value={i.id} style={{ display: 'block' }}>

																		<div>{i.n}</div>


																	</MenuItem>
																}


															})}

														</Select>
													</FormControl>
												</Grid>

											</Grid>
										</td>
									</tr>}
									{/* {data.pilihan_1 && <tr>
										<td style={{ verticalAlign: 'top' }}>
											<div style={{ marginRight: 10, alignItems: 'center', display: 'flex', marginTop: 14, justifyContent: 'center', background: '#ccc', borderRadius: '100%', width: 24, color: 'black', fontWeight: 'bold', textAlign: 'center', height: 24 }}>
												3
											</div>
										</td>
										<td width="100%">

											<Grid container spacing={3}>

												<Grid item xs={12} sm={8}>

													<FormControl variant="filled" fullWidth   >
														<InputLabel >Pilihan 3</InputLabel>
														<Select
															onChange={onChangeSelectSchool}
															value={data.pilihan_3}
															inputProps={{ name: 'pilihan_3' }}
															renderValue={(v) => schs_map[v].n}
														>
															{schs.map((i) => {
																if (i.id == data.pilihan_1) {
																	return null;
																}
																if (i.id == data.pilihan_2) {
																	return null;
																}
																if (region2 != i.region) {
																	region2 = i.region;
																	return [
																		<ListSubheader key={'rayon-' + i.region}>{RAYON[i.region]}</ListSubheader>

																		, <MenuItem key={i.id} value={i.id} style={{ display: 'block' }}>

																			<div>{i.n}</div>


																		</MenuItem>
																	]
																} else {

																	return <MenuItem key={i.id} value={i.id} style={{ display: 'block' }}>

																		<div>{i.n}</div>


																	</MenuItem>
																}


															})}

														</Select>
													</FormControl>
												</Grid>

											</Grid>
										</td>
									</tr>} */}


								</tbody>
							</table>

						</>
						}

					</div>
				</>}


				{step > 30 && <><div style={{ display: 'flex', alignItems: 'center' }}>
					<div style={{ marginRight: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', background: '#1E6887', borderRadius: '100%', width: 24, color: 'white', fontWeight: 'bold', textAlign: 'center', height: 24 }}>
						2
					</div>
					<div style={{ flex: 1 }}>
						<Typography variant="h6" style={{ color: '#1E6887' }}>Biodata</Typography>
					</div>
				</div>
					<div className="enroll-block">
						<Grid container spacing={3}>

							<Grid item xs={12} sm={6}>
								<TextField
									name="nisn"
									error={(errors['nisn'])}
									onChange={onChange}
									value={data.nisn}
									fullWidth
									variant="filled"
									helperText={errors['nisn']}
									label="NISN*"
								/>
							</Grid>
							<Grid item xs={12} sm={6} style={{ padding: 0 }}></Grid>
							<Grid item xs={12} sm={8} md={6}>
								<TextField
									error={(errors['nama'])}
									name="nama"
									onChange={onChange}
									value={data.nama}
									fullWidth
									variant="filled"
									label="Nama Lengkap*"
								/>
							</Grid>
							<Grid item xs={12} sm={4} md={4}>
								<FormControl variant="filled" fullWidth   >
									<InputLabel >Jenis Kelamin</InputLabel>
									<Select
										onChange={onChangeSelect}
										value={data.jk}
										inputProps={{ name: 'jk' }}
										label="Jenis Kelamin*"
										variant="filled"
										fullWidth
									>
										<MenuItem value="">-</MenuItem>
										<MenuItem value={1}>Laki-laki</MenuItem>
										<MenuItem value={2}>Perempuan</MenuItem>
									</Select>
								</FormControl>
							</Grid>

							<Grid item xs={12} sm={false} md={2} style={{ padding: 0 }}></Grid>
							<Grid item xs={12} sm={8} md={6} >
								<TextField
									name="tempat_lahir"
									value={data.tempat_lahir}
									onChange={onChange}
									fullWidth
									variant="filled"
									label="Tempat Lahir*"
									error={(errors['tempat_lahir'])}
									helperText={errors['tempat_lahir']}
								/>
							</Grid>
							<Grid item xs={12} sm={4} >
								<TextField
									name="tanggal_lahir"
									value={data.tanggal_lahir}
									onChange={onChange}
									fullWidth
									placeholder=""
									type="date"
									variant="filled"
									label="Tanggal Lahir*"
									error={(errors['tanggal_lahir'])}
									helperText={errors['tanggal_lahir']}
								/>
							</Grid>
							<Grid item xs={12} sm={false} md={2} style={{ padding: 0 }}></Grid>
							<Grid item xs={12} sm={4} md={3}>
								<TextField
									name="hp"
									value={data.hp}
									onChange={onChange}
									fullWidth
									variant="filled"
									label="Nomor WA*"
									error={(errors['hp'])}
									helperText={errors['hp']}
								/>
							</Grid>
							<Grid item xs={12} sm={8} md={7} >
								<TextField
									name="email"
									onChange={onChange}
									value={data.email}
									fullWidth
									variant="filled"
									label="Alamat Email"
									error={(errors['email'])}
									helperText={errors['email']}
								/>
							</Grid>

							<Grid item xs={12} sm={8} md={6}>

								{smps.length === 0 && <div className="spinner"><div className="bounce1"></div><div className="bounce2"></div><div className="bounce3"></div></div>}
								{smps.length > 0 && <Autocomplete style={{ marginTop: 0 }}
									options={smps}
									getOptionLabel={(option) => option.title}
									value={smp}

									filterOptions={(opts, state) => {
										const search = state.inputValue.toUpperCase().replace('MTSN', "MTS NEGERI");

										if (search == "") {
											return [];
										}
										let result = [];
										const len = opts.length;

										for (let i = 0; i < len; i++) {

											if (opts[i].title.indexOf(search) > -1) {
												result.push(opts[i]);
											}

											if (result.length > 10) break;

										}

										if (result.length < 10) {
											result.push({ id: -1, title: 'Sekolah lainnya / Tidak ada dalam daftar' });
										}


										return result;

									}}
									autoHighlight
									onChange={(e, v) => {


										let x = { ...data, sekolah: v.id, sekolah_npsn: v.npsn, sekolah_nama: v.name };
										setSmp(v);
										setData(x);
									}}

									noOptionsText="Masukkan no NPSN atau nama sekolah asal Anda"
									disableClearable
									renderInput={(params) => <TextField {...params} name="sekolah" margin="normal" fullWidth variant="filled" label="Asal Sekolah" />}
								/>}

							</Grid>
							<Grid item xs={12} sm={4} >
								<FormControl variant="filled" fullWidth   >
									<InputLabel >Thn. Lulus*</InputLabel>
									<Select
										onChange={onChangeSelect}
										value={data.sekolah_lulus}
										inputProps={{ name: 'sekolah_lulus' }}
										label="Thn. Lulus*"
										variant="filled"
										placeholder='2024'
										fullWidth
									>
										<MenuItem value={2024}>2024</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={false} md={2} style={{ padding: 0 }}></Grid>

							{data.sekolah === -1 && <><Grid item xs={12} sm={4} md={3}>
								<TextField
									name="sekolah_npsn"
									onChange={onChange}
									value={data.sekolah_npsn}
									fullWidth
									variant="filled"
									label="NPSN Asal Sekolah*"
								/>
							</Grid>
								<Grid item xs={12} sm={8} md={9} >
									<TextField
										name="sekolah_nama"
										onChange={onChange}
										value={data.sekolah_nama}
										fullWidth
										variant="filled"
										label="Nama Asal Sekolah*"
									/>
								</Grid></>}

						</Grid>

					</div>
				</>}

				<div style={{ display: 'none', alignItems: 'center' }}>
					<div style={{ marginRight: 10, alignItems: 'center', display: 'none', justifyContent: 'center', background: '#1E6887', borderRadius: '100%', width: 24, color: 'white', fontWeight: 'bold', textAlign: 'center', height: 24 }}>
						3
					</div>
					<div style={{ flex: 1, display: "none" }}>
						<Typography variant="h6" style={{ color: '#1E6887', display: 'none' }}>Input Nilai <span style={{ color: '#808080', fontSize: '80%' }}>(Rentang nilai: 10-100)</span></Typography>

					</div>
				</div>
				{/* <div className="enroll-block" style={{ display: "none" }}>


					<Typography variant="subtitle2" style={{ marginBottom: 10, fontSize: '105%' }} >Nilai Rerata 4 Mapel (5 Semester)</Typography>
					<Grid container spacing={3}>

						<Grid item xs={6} sm={3} md={3}>
							<TextField
								name="n_mat"
								value={data.n_mat = null}
								onChange={onChange}
								fullWidth
								variant="filled"
								label="Matematika*"

							// error={(errors['n_mat'])}
							// helperText={errors['n_mat']}
							/>
						</Grid>
						<Grid item xs={6} sm={3} md={3}>
							<TextField
								name="n_indo"
								value={data.n_indo = null}
								onChange={onChange}

								fullWidth
								variant="filled"
								label="B. Indonesia*"

							// error={(errors['n_indo'])}
							// helperText={errors['n_indo']}
							/>
						</Grid>

						<Grid item xs={6} sm={3} md={3}>
							<TextField
								name="n_ipa"
								value={data.n_ipa = null}
								onChange={onChange}
								fullWidth
								variant="filled"
								label="IPA*"

							// error={(errors['n_ipa'])}
							// helperText={errors['n_ipa']}
							/>
						</Grid>
						<Grid item xs={6} sm={3} md={3}>
							<TextField
								name="n_ing"
								value={data.n_ing = null}
								onChange={onChange}
								fullWidth
								variant="filled"
								label="B. Inggris*"

							// error={(errors['n_ing'])}
							// helperText={errors['n_ing']}
							/>
						</Grid>
					</Grid>
				</div> */}
				{/* Tahfidz Start */}
				{step > 40 && <><div style={{ display: 'flex', alignItems: 'center' }}>
					<div style={{ marginRight: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', background: '#1E6887', borderRadius: '100%', width: 24, color: 'white', fontWeight: 'bold', textAlign: 'center', height: 24 }}>
						3
					</div>
					<div style={{ flex: 1 }}>
						<Typography variant="h6" style={{ color: '#1E6887' }}>Tahfidz</Typography>

					</div>
				</div>
					<div className="enroll-block">
						<Grid container spacing={3}>
							<Grid item xs={12} sm={4} md={4}>
								<FormControl variant="filled" fullWidth>
									<Typography variant="body1" gutterBottom>Total Hafalan Juz: {data.p_tahfidz}</Typography>
									<Grid container spacing={1}>
										{[...Array(30).keys()].map((index) => (
											<Grid item xs={4} key={index}>
												<FormControlLabel
													control={
														<Checkbox
															checked={checkedItems[index]}
															onChange={() => handleCheckboxChange(index)}
															inputProps={{ 'aria-label': `Juz ${index + 1}` }}
														/>
													}
													label={`Juz ${index + 1}`}
												/>
											</Grid>
										))}
									</Grid>
								</FormControl>
							</Grid>
							{/* <Grid item xs={6} sm={4} md={3}>
						<FormControl variant="filled" fullWidth>
							<InputLabel >Do'a</InputLabel>
							<Select
							onChange={onChangeSelect}
							value={data.p_doa}
							inputProps={{ name: 'p_doa' }}
							label="Peringkat"
							variant="filled"
							fullWidth
							>
							<MenuItem value="5">5</MenuItem>
							<MenuItem value="10">10</MenuItem>
							<MenuItem value="15">15</MenuItem>
							<MenuItem value="20">20</MenuItem>
							<MenuItem value="25">&gt; 20</MenuItem>
							</Select>
						</FormControl>
						</Grid> */}
							{/* Upload Sertif Tahfidz */}
							{data.p_tahfidz >= 0 && (
								<Grid item xs={12} sm={4} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
									<Typography variant="body1" gutterBottom>Scan Sertifikat/Ijazah Tahfidz</Typography>
									<Upload onChange={(x) => setData({ ...data, file_tahfiz: x })} />
									{/* <div className="text-attach_1">* Pastikan jenis berkas sesuai serta ukuran maksimal tiap berkas 10MB</div>
							<div className="text-attach_2">* Jika lebih dari 1 file format dalam bentuk .zip</div> */}
								</Grid>
							)}
						</Grid>
					</div></>}
				{/* Upload Berkas hide */}
				{<div style={{ display: (step > 50) ? 'block' : 'none' }}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ marginRight: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', background: '#1E6887', borderRadius: '100%', width: 24, color: 'white', fontWeight: 'bold', textAlign: 'center', height: 24 }}>
							4
						</div>
						<div style={{ flex: 1 }}>
							<Typography variant="h6" style={{ color: '#1E6887' }}>Unggah Berkas</Typography>

						</div>
					</div>
					<div className="enroll-block">
						<p style={{ color: 'darkred' }}>* Pastikan jenis berkas sesuai serta ukuran maksimal tiap berkas 10MB</p>
						<p style={{ color: 'darkred' }}>* Jika lebih dari 1 halaman harap di gabungkan ke dalam 1 ZIP/PDF</p>
						<Grid container spacing={3}>

							<Grid item xs={12} sm={4} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{ flex: 1 }}>Pas Foto Resmi*</div>
								<Upload imageOnly onChange={(x) => setData({ ...data, file_photo: x })} />
							</Grid>
							<Grid item xs={12} sm={4} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{ flex: 1 }}>Scan Surat Keterangan Siswa*</div>
								<Upload onChange={(x) => setData({ ...data, file_skl: x })} />
							</Grid>
							<Grid item xs={12} sm={4} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{ flex: 1 }}>Scan KK*</div>
								<Upload onChange={(x) => setData({ ...data, file_un: x })} />
							</Grid>
						</Grid>
					</div>
				</div>}
				{/* Tahfidz Stop */}
				{/* Prestasi Start */}
				{/* {step > 40 && <><div style={{ display: 'flex', alignItems: 'center' }}>
					<div style={{ marginRight: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', background: '#1E6887', borderRadius: '100%', width: 24, color: 'white', fontWeight: 'bold', textAlign: 'center', height: 24 }}>
						4
					</div>
					<div style={{ flex: 1 }}>
						<Typography variant="h6" style={{ color: '#1E6887' }}>Prestasi Terbaik</Typography>
						 <span style={{ color: '#808080', fontSize: '80%' }}>(Bila ada)</span> 

					</div>
				</div>
					<div className="enroll-block">
						 <Grid container spacing={3}>
							<Grid item xs={12} sm={4} md={4}>
								<FormControl variant="filled" fullWidth   >
									<InputLabel >Jumlah Prestasi</InputLabel>
									<Select
										onChange={onChangeSelect}
										value={data.p_jumlah}
										inputProps={{ name: 'p_jumlah' }}

										label="Jenis Kelamin*"
										variant="filled"
										fullWidth
									><MenuItem value="0">Tidak ada prestasi</MenuItem>
										{[...Array(10).keys()].map((e, i) => <MenuItem value={i + 1}>{i + 1} Prestasi</MenuItem>)}
									</Select>
								</FormControl>
							</Grid>
						</Grid> 
						<Grid container spacing={3}>
							<Grid Grid item xs={6} sm={4} md={3}>
								<FormControl variant="filled" fullWidth   >
									<InputLabel >Tingkat Kejuaraan</InputLabel>
									<Select
										onChange={onChangeSelect}
										value={data.p_tingkat}
										inputProps={{ name: 'p_tingkat' }}
										label="Tingkat"
										variant="filled"
										fullWidth
									>
										<MenuItem value="">-</MenuItem>
										<MenuItem value={1}>Internasional</MenuItem>
										<MenuItem value={2}>Nasional</MenuItem>
										 <MenuItem value={3}>Regional</MenuItem> 
										<MenuItem value={4}>Provinsi</MenuItem>
										<MenuItem value={5}>Kabupaten</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6} sm={4} md={3}>
								<FormControl variant="filled" fullWidth   >
									<InputLabel >Juara</InputLabel>
									<Select
										onChange={onChangeSelect}
										value={data.p_juara}
										inputProps={{ name: 'p_juara' }}

										label="Peringkat"
										variant="filled"
										fullWidth
									>
										<MenuItem value="">-</MenuItem>
										<MenuItem value={1}>Juara 1</MenuItem>
										<MenuItem value={2}>Juara 2</MenuItem>
										<MenuItem value={3}>Juara 3</MenuItem>

									</Select>
								</FormControl>

							</Grid>

							 
							<Grid item xs={6} sm={4} md={3}>
								<FormControl variant="filled" fullWidth   >
									<TextField
										error={(errors['p_nama_kejuaraan'])}
										name="p_nama_kejuaraan"
										onChange={onChange}
										value={data.p_nama_kejuaraan}
										fullWidth

										variant="filled"
										label="Nama Kejuaraan*"
									/>
								</FormControl>

							</Grid>
							 
							{data.p_tingkat >= 0 && <Grid item xs={12} sm={4} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{ flex: 1 }}>Scan Piagam Prestasi Kejuaraan<br /></div>
								<Upload onChange={(x) => setData({ ...data, file_prestasi: x })} />
								<div className="text-attach_1">* Pastikan jenis berkas sesuai serta ukuran maksimal tiap berkas 10MB</div>
								<div className="text-attach_2">* Jika lebih dari 1 file format dalam bentuk .zip</div>
							</Grid>
							}</Grid>
					</div></>} */}
				{/* Prestasi Stop */}
				{step > 70 && <><div style={{ display: 'flex', alignItems: 'center' }}>
					<div style={{ marginRight: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', background: '#1E6887', borderRadius: '100%', width: 24, color: 'white', fontWeight: 'bold', textAlign: 'center', height: 24 }}>
						5
					</div>
					<div style={{ flex: 1 }}>
						<Typography variant="h6" style={{ color: '#1E6887' }}>Data Orang Tua / Wali</Typography>

					</div>
				</div>
					<div className="enroll-block">

						<Grid container spacing={3}>

							<Grid item xs={12} sm={6} md={6}>
								<TextField
									name="ortu_nama"
									value={data.ortu_nama}
									onChange={onChange}
									fullWidth
									variant="filled"
									label="Nama Orang Tua/Wali*"
								/>
							</Grid>
							<Grid item xs={false} sm={6} md={6} style={{ padding: 0 }}></Grid>
							<Grid item xs={12} sm={4} md={3}>
								<TextField
									name="ortu_hp"
									value={data.ortu_hp}
									onChange={onChange}
									fullWidth
									variant="filled"
									label="Nomor HP/WA*"
								/>
							</Grid>
							<Grid item xs={12} sm={8} md={9}>
								<TextField
									name="ortu_alamat"
									value={data.ortu_alamat}
									onChange={onChange}
									fullWidth
									variant="filled"
									label="Alamat*"
								/>
							</Grid>

						</Grid>

					</div>
				</>}

				{/* Upload Berkas hide */}
				{/* {<div style={{ display: (step > 10) ? 'block' : 'none' }}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ marginRight: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', background: '#1E6887', borderRadius: '100%', width: 24, color: 'white', fontWeight: 'bold', textAlign: 'center', height: 24 }}>
							6
						</div>
						<div style={{ flex: 1 }}>
							<Typography variant="h6" style={{ color: '#1E6887' }}>Unggah Berkas</Typography>

						</div>
					</div>
					<div className="enroll-block">
						<p style={{ color: 'darkred' }}>* Pastikan jenis berkas sesuai serta ukuran maksimal tiap berkas 10MB</p>
						<p style={{ color: 'darkred' }}>* Jika lebih dari 1 halaman harap di gabungkan ke dalam 1 ZIP/PDF</p>
						<Grid container spacing={3}>

							<Grid item xs={12} sm={4} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{ flex: 1 }}>Pas Foto Resmi*</div>
								<Upload imageOnly onChange={(x) => setData({ ...data, file_photo: x })} />
							</Grid>
							<Grid item xs={12} sm={4} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{ flex: 1 }}>Scan SKL*<br /> (berisi nilai rerata 5 semester dari 4 mapel)</div>
								<Upload onChange={(x) => setData({ ...data, file_skl: x })} />
							</Grid>
							<Grid item xs={12} sm={4} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{ flex: 1 }}>Scan Hasil UN SD/MI*<br /> (berisi nilai dari 3 mapel)</div>
								<Upload onChange={(x) => setData({ ...data, file_un: x })} />
							</Grid>
							<Grid item xs={12} sm={4} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{ flex: 1 }}>Scan Piagam Prestasi Kejuaraan<br /> (Hanya untuk yang memiliki)</div>
								<Upload onChange={(x) => setData({ ...data, file_prestasi: x })} />
							</Grid>
							<Grid item xs={12} sm={4} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{ flex: 1 }}>Scan Sertifikat/Ijazah Tahfidz  <br /> (Hanya untuk yang memiliki)</div>
								<Upload onChange={(x) => setData({ ...data, file_tahfiz: x })} />
							</Grid>
						</Grid>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={4} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{ flex: 1 }}>Scan SHUAMBN<br /> (Hanya untuk yang memiliki)</div>
								<Upload onChange={(x) => setData({ ...data, file_shuambn: x })} />
							</Grid>
						</Grid>
					</div>
				</div>} */}
				{step < 90 && <div>

					<div style={{ display: 'flex', alignItems: 'center', alignItems: 'top' }}>
						<div style={{ marginRight: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', background: '#808080', borderRadius: '100%', width: 24, color: 'white', fontWeight: 'bold', textAlign: 'center', height: 24 }}>
							&#8675;
						</div>
						<div style={{ flex: 1 }}>
							<Typography variant="subtitle1" style={{ color: '#404040' }}>Lengkapi isian dengan lengkap untuk lanjut ke bagian berikutnya</Typography>

						</div>
					</div>
				</div>}
				{step > 90 && <><div>

					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ marginRight: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', background: '#1E6887', borderRadius: '100%', width: 24, color: 'white', fontWeight: 'bold', textAlign: 'center', height: 24 }}>
							&#x2714;
						</div>
						<div>
							<Typography variant="h6" style={{ color: '#1E6887' }}>Selesai</Typography>
						</div>
					</div>
				</div>
					<div className="enroll-block" style={{ border: 'none', display: 'flex', alignItems: 'top' }}>
						<div><Checkbox checked={agree} color={'primary'} onClick={(e) => { setAgree((x) => !x); getToken(+new Date()) }}></Checkbox> </div>
						<div>Dengan ini saya menyatakan seluruh data dan dokumen yang saya berikan adalah benar dan siap diverifikasi.
						</div>
					</div>
					<div style={{ margin: 20, textAlign: 'center', }}>

						<Button onClick={() => doSubmit()} variant="contained" size="large" disableElevation color="primary" disabled={!agree || !token} >Daftar</Button>

					</div>
				</>}
			</Container>

			{
				submitProgress !== false && <div className="loading-screen">
					<div><div className="spinner"><div className="bounce1"></div><div className="bounce2"></div><div className="bounce3"></div></div>
						<br /><br /><br />
						{(submitProgress.progress === 100) ? 'Memproses...' : `Mengunggah... (${filesize(submitProgress.progressCurrent)} / ${filesize(submitProgress.progressTotal)})`}
						{(submitProgress.progress < 100) && <div style={{ width: 300, position: 'relative', margin: 10, height: 10, borderRadius: 10, border: '1px solid #1E6887' }}>
							<div style={{ width: `${submitProgress.progress}%`, borderRadius: 10, height: 10, background: 'rgb(6, 109, 153)' }}></div>
						</div>}
					</div>
				</div>
			}


		</div>


	);
}

