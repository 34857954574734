import React, { useState, useEffect } from 'react';
import logo from './img/logo.png';

import axios from 'axios';

import QRCode from 'qrcode.react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const idmonths = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

const formatDate = (t) => {
    const d = new Date(t);
    const dd = d.getDate();



    const dm = idmonths[d.getMonth()];
    const dy = d.getFullYear();

    return `${dd} ${dm} ${dy}`
}
function Result(props) {


    const [data, setData] = useState({});
    const [data2, setData2] = useState({});

    const [error, setError] = useState(false);

    const [show, setShow] = useState(false);

    const [summary, setSummary] = useState({});

    const getSummary = () => {


        axios.get('/api/admin/hasil/dashboard')
            .then(function (response) {
                let x = response.data;
                setSummary(x);
            })
            .catch(function (error) {
                // handle error
                // console.log(error);
            })
            .then(function () {
                // always executed
            });


    };

    const doLoad = (id) => {

        axios.get('/api/result', { params: { id: id } })
            .then(function (response) {

                setData(response.data);
            })
            .catch(function (error) {
                // handle error
                setError(error);



            })
            .then(function () {
                // always executed


            });


    }

    useEffect(() => {
        getSummary();
        doLoad(props.match.params.id);

    }, [props.match.params.id]);


    if (error !== false) {

        return (<div className="">

            <div className="no-print" style={{ textAlign: 'center', background: '#eee', padding: 10 }} >
                <div className="container">

                    <div style={{ fontSize: '32px', fontWeight: 'bold', padding: '10px 0' }}>PORTAL</div>
                    <div style={{ fontSize: '24px', fontWeight: 'normal', padding: '10px 0' }}>Hasil Seleksi Tahap I PPDB JPTT 2024</div>

                </div>
            </div>

            <div className="no-print" style={{ margin: 80 }}></div>

            <div className="container no-print" style={{ textAlign: 'center', padding: 2 }}>
                <Grid container direction="column"
                    justifyContent="center"
                    alignItems="center" style={{ paddingBottom: '30px' }}>
                    <div style={{ fontWeight: "bold", fontSize: '18px', width: 400 }} >Mohon Maaf, data tidak ditemukan.
                        silahkan menghubungi madrasah yang didaftar</div>
                </Grid>
            </div>
        </div>);

    }

    return (
        <div className="">

            {!(props.history.location.state && props.history.location.state.success) && <><div className="no-print" style={{ textAlign: 'center', background: '#eee', padding: 10 }} >
                <div className="container">

                    <div style={{ fontSize: '32px', fontWeight: 'bold', padding: '10px 0' }}>PORTAL</div>
                    <div style={{ fontSize: '24px', fontWeight: 'normal', padding: '10px 0' }}>Hasil Seleksi Tahap I PPDB JPTT 2024</div>

                </div>
            </div>

                <div className="no-print" style={{ margin: 50 }}></div>

                <div className="container no-print" style={{ textAlign: 'center', padding: 2 }}>
                    <Grid container direction="column"
                        justifyContent="center"
                        alignItems="center" style={{ paddingBottom: '30px' }}>
                        <div style={{ fontWeight: "bold", fontSize: '42px' }}>{data.nama_result}</div>
                        <div style={{ fontWeight: "bold", fontSize: '18px', margin: '8' }} > NISN: {data.nisn_result} </div>
                    </Grid>

                    {data.result && <div>
                        {data.status && <div> <h2 style={{ color: "#008000" }}>Selamat! Anda Diterima di PPDB JPTT Online di <b>{data.diterima}</b>.</h2>

                            <p><a href="https://docs.google.com/spreadsheets/d/1WsnkruZikvMySsazFay7vQm6zhgM9wY1/edit?usp=sharing&ouid=107731123210521055052&rtpof=true&sd=true" target="_blank" style={{ color: "#006190" }}>

                                Selanjutnya, silahkan menghubungi Madrasah Penerima.</a>

                            </p>

                        </div>}

                        {!data.status && <Grid container direction="column"
                            justifyContent="center"
                            alignItems="center" style={{ paddingBottom: '30px' }}>
                            <div style={{ fontWeight: "bold", fontSize: '18px', width: 400 }} >Mohon Maaf, data tidak ditemukan.
                                silahkan menghubungi madrasah yang didaftar</div></Grid>}

                        {/* {data.cadangan && data.cadangan.length > 0 && <div>
							<h3>Cadangan</h3>

							{data.cadangan.map((i, idx) => <p key={idx}>&bull; {i.sekolah} ({i.jurusan}) </p>)}
			
						</div>} */}



                    </div>}
                </div>

            </>

            }

        </div>




    );
}

export default Result;
