import React, { useState, useEffect } from 'react';


import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import StudentList2 from './StudentList2'
import { Link } from 'react-router-dom';

const JURUSAN = { "1": "", "2": "", "3": "", "4": "" };


export default function Admin(props) {


    const id = props.match.params.id;




    const [school, setSchool] = useState();
    const [summary, setSummary] = useState({});
    const [summaryJurusan, setSummaryJurusan] = useState({});

    const getSummary = () => {


        axios.get('/api/admin/hasil/dashboard')
            .then(function (response) {
                let x = response.data;
                setSummary(x);
            })
            .catch(function (error) {
                // handle error
                // console.log(error);
            })
            .then(function () {
                // always executed
            });


    };
    const getJurusanSummary = () => {

        let url = '/api/admin/jurusan';

        if (props.hasil) {
            url = '/api/admin/hasil/jurusan';
        }

        axios.get(url)
            .then(function (response) {
                let x = response.data;

                const y = x.reduce((obj, item) => {


                    const key = item.sid + '_' + item.jid

                    return {
                        ...obj,
                        [key]: item,
                    };
                }, {});


                setSummaryJurusan(y);
            })
            .catch(function (error) {
                // handle error
                // console.log(error);
            })
            .then(function () {
                // always executed
            });


    };


    function getSchools() {


        axios.get('/api/school2')
            .then(function (response) {
                // setSchools(response.data)


                for (let [key, value] of Object.entries(response.data)) {

                    const s = value.find(x => x.id == id);


                    if (s) {
                        setSchool(s);
                        break;
                    }
                }

            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });

    }


    useEffect(() => {
        setSchool(false)
        getSchools();
        getJurusanSummary();
        getSummary();

    }, [props])

    if (!school) {
        return <div style={{ margin: 50 }}><div className="spinner"><div className="bounce1"></div><div className="bounce2"></div><div className="bounce3"></div></div></div>
    }

    const jurusan = Object.keys(school.j);
    return (

        <div className="">

            <div style={{ textAlign: 'center', background: '#eee', padding: 1 }} >
                <div className="container">

                    <p style={{ fontSize: '32px', fontWeight: 'bold' }}>{school.n}</p>
                    {!props.hasil && <h2>Pendaftar</h2>}
                    {props.hasil && <p style={{ fontSize: '24px' }}>Hasil Seleksi Tahap I PPDB JPTT 2024</p>}

                </div>
            </div>
            <div style={{ textAlign: 'center', background: '#eee', padding: 10, borderBottom: '1px solid #c0c0c0', position: 'sticky', top: 0, zIndex: 99 }} >
                {jurusan.map(j => <a style={{ margin: 10 }} key={j} href={`#${JURUSAN[j]}`}>{JURUSAN[j]}</a>)}
            </div>
            <div style={{ margin: 50 }}></div>
            <Container style={{ minHeight: '100vh' }}>

                {jurusan.map(j => {

                    let p1 = '', p2 = '', p3 = '', p4 = '', total = '';
                    if (summaryJurusan[id + '_' + j]) {

                        p1 = parseInt(summaryJurusan[id + '_' + j].p1);
                        p2 = parseInt(summaryJurusan[id + '_' + j].p2);
                        p3 = parseInt(summaryJurusan[id + '_' + j].p3);
                        p4 = parseInt(summaryJurusan[id + '_' + j].p4);
                        total = parseInt(summaryJurusan[id + '_' + j].total);

                    }


                    return <div><Grid container spacing={3} style={{ textAlign: 'center' }} justify="center">

                        <Grid item xs={12} sm={3}>

                            <a name={JURUSAN[j]}><h2 style={{ color: '#1E6887' }}>{JURUSAN[j]}</h2></a>

                            <table width="100%" cellPadding={10} >
                                <thead style={{ backgroundColor: '#eee' }}>
                                    <tr>
                                        <td align='left'>Kuota</td>
                                        <td align='left'>{school.j[j]}</td>
                                    </tr>

                                    {/* <tr>
                                        <td >Pilihan 1</td>
                                        <td>{p1 || ''}</td>
                                    </tr>
                                    <tr>
                                        <td >Pilihan 2</td>
                                        <td>{p2 || ''}</td>
                                    </tr>
                                    <tr>
                                        <td >Pilihan 3</td>
                                        <td>{p3 || ''}</td>
                                    </tr> */}
                                    {/* <tr>
                                                <td >Pilihan 4</td>   
                                                <td>{p4 || ''}</td>   
                                            </tr> */}
                                    <tr>
                                        <td align='left'>Pendaftar</td>
                                        <td align='left'>{total || ''}</td>
                                    </tr>
                                    <tr>
                                        <td align='left'>Status Data</td>
                                        <td align='left' style={{ color: 'brown' }}>Sementara</td>
                                    </tr>
                                    <tr>
                                        <td align='left'>Pembaruan Terakhir</td>
                                        <td align='left' style={{ color: 'brown' }}>{summary.t}</td>
                                    </tr>
                                </thead>


                            </table>

                        </Grid>
                        <Grid item xs={12} sm={9}>
                            {/* <StudentList title={`${school.n} - ${JURUSAN[j]} - Diterima`} pilihan hasil={props.hasil} filter={{ sid: school.id, jid: j, source: (props.hasil ? 'hasil' : 'school') }} /> */}
                            <StudentList2 title={`${school.n} - Diterima`} pilihan hasil={props.hasil} filter={{ sid: school.id, jid: j, source: (props.hasil ? 'hasil' : 'school') }} />
                            {/* {props.hasil && <>
                                <h4>Cadangan</h4>
                                <StudentList title={`${school.n} - ${JURUSAN[j]} - Cadangan`} pilihan hasil={props.hasil} filter={{ sid: school.id, jid: j, source: 'cadangan' }} search={false} />
                                <StudentList title={`${school.n} - Cadangan`} pilihan hasil={props.hasil} filter={{ sid: school.id, jid: j, source: 'cadangan' }} search={false} />
                            </>} */}
                        </Grid>
                    </Grid>
                    </div>

                })}



            </Container>


        </div>

    )
}