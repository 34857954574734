import React, { useEffect, useState } from "react";
import banner from "./img/image6.png";
import brosur from "./img/brosur2024.jpg";
import brosur1 from "./img/Revisi Pelaksanaan PPDB JPTT TA 2024-rev1.pdf";
import "./home2.css";
import logo from "./img/logo.png";
import registrasion from "./img/registration.svg";
import announcement from "./img/announcement.svg";
import interview from "./img/interview.svg";
import announce from "./img/announce.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Axios from "axios";

function Home() {
  const [setting, setSetting] = useState([]);
  const [data, setData] = useState({
    PPDB_REGISTER: window.PPDB_REGISTER,
    TAHAP_1: window.TAHAP_1,
    TAHAP_2: window.TAHAP_2,
    PPDB_RESULT: window.PPDB_RESULT,
  });

  // useEffect(() => {
  //   async function fetchDataSetting() {
  //     try {
  //       const response = await Axios.get(
  //         "/api/admin/setting/ppdb_result"
  //       );

  //       setSetting(response.data);
  //       console.log(response.data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   }

  //   fetchDataSetting();
  // }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        // Ambil data dari '/api/admin/setting/ppdb_result'
        const responseResult = await Axios.get(
          "/api/admin/setting/ppdb_result"
        );
        const resultData = responseResult.data;

        // Ambil data dari '/api/admin/setting'
        const responseSetting = await Axios.get("/api/admin/setting");
        const settingData = responseSetting.data;

        // Gabungkan data dari '/api/admin/setting' dengan data yang sudah ada
        const combinedData = { ...resultData, ...settingData };

        // Set state data dengan data yang digabungkan
        setSetting(combinedData);
        console.log(combinedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    //BANNER
    <div className="">
      <div style={{ position: "relative", width: "100%", height: "auto" }}>
        <img
          className="banner-home"
          src={banner}
          alt="home"
          // style={{
          //   width: "100%",
          //   height: "auto",
          //   borderBottomLeftRadius: "50%",
          //   borderBottomRightRadius: "50%",
          // }}
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: "white",
            width: "80%",
            maxWidth: "500px",
          }}
        >
          <img
            className="logo-banner"
            src={logo}
            alt="logo-banner"
            // style={{ width: "30%", height: "auto", marginBottom: "3vw" }}
          />
          <p className="title-home">
            Penerimaan Peserta Didik Baru Jalur Prestasi Tahfidz Terpadu (JPTT)
            Madrasah Aliyah Se DI Yogyakarta
          </p>
          <h5 className="thn">Tahun Ajaran 2024/2025</h5>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "2vw",
            }}
          >
            {/* <Link
              to="http://ppdb-mi.jogjamadrasahdigital.net/daftar"
              style={{ color: "inherit", textDecoration: "none" }}
            > */}
            {data.PPDB_REGISTER == 0 && (
              <button
                className="button"
                disabled
                style={{ backgroundColor: "#ccc", color: "#555" }}
              >
                Belum Dibuka
              </button>
            )}
            {data.PPDB_REGISTER === 1 && (
              <a
                href="https://ppdb-ma.jogjamadrasahdigital.net/daftar"
                rel="noopener noreferrer"
              >
                <button
                  className="button"
                  style={{ backgroundColor: "green", color: "white" }}
                >
                  Daftar Sekarang
                </button>
              </a>
            )}
            {data.PPDB_REGISTER == 2 && (
              <button
                className="button"
                disabled
                style={{ backgroundColor: "#ccc", color: "#555" }}
              >
                Pendaftaran Ditutup
              </button>
            )}
            {/* </Link>{" "} */}
            {/* <Link
              to="http://ppdb-mi.jogjamadrasahdigital.net/hasil"
              style={{ color: "inherit", textDecoration: "none" }}
            > */}
            {/* <button className="button" disabled style={{ backgroundColor: '#ccc', color: '#555' }}>Cek Bukti Pendaftaran</button> */}
            {/* </Link> */}
          </div>
        </div>
      </div>

      {/* ALUR PENDAFTARAN */}
      <div
        id="alur-pendaftaran"
        className="judul_middle"
        style={{
          textAlign: "center",
          color: "#006316",
          fontSize: "16px",
          paddingTop: "20px",
          paddingBottom: "2px",
        }}
      >
        <div>
          <p className="judul_1">
            Selamat Datang di PPDB JPTT 2024 Kanwil Kemenag DIY
          </p>
          <p className="judul_2">
            Situs ini dipersiapkan sebagai pengganti pusat informasi dan
            pengolahan seleksi data siswa peserta PPDB JPTT MA se DIY Periode
            2024 / 2025
          </p>
        </div>
      </div>

      <div
        className="judul_middle"
        style={{
          textAlign: "center",
          fontSize: "16px",
          paddingTop: "20px",
        }}
      >
        <div>
          <p className="judul_3">
            ALUR PENDAFTARAN JPTT (Jalur Prestasi Tahfidz Terpadu)
          </p>
        </div>
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ marginTop: "50px" }}
      >
        <Row className="d-flex justify-content-center align-items-center">
          <Col md={4}>
            <Card
              className="card-custom"
              style={{
                marginBottom: "50px",
                borderRadius: "10px",
                backgroundColor: "#057A55",
                height: "90%",
                color: "#ffff",
                position: "relative",
              }}
            >
              <Card.Body className="card-content">
                <Card.Title className="card-title text-center">
                  <h5 style={{ fontWeight: "700", paddingTop: "20px" }}>
                    Pendaftaran
                  </h5>
                </Card.Title>
                <h6
                  className="d-flex justify-content-center text-center"
                  style={{ paddingTop: "30px" }}
                >
                  23 April 2024 Pukul 00.00
                  <br />
                  sampai dengan
                  <br />
                  25 April 2024 Pukul 14.00
                </h6>
                <div className="number-container d-flex justify-content-center align-items-center">
                  <span
                    style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                  >
                    1
                  </span>
                </div>
                <div className="image-container d-flex justify-content-center align-items-center">
                  <img
                    alt="announcement"
                    src={registrasion}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card
              className="card-alur"
              style={{
                marginBottom: "50px",
                borderRadius: "10px",
                backgroundColor: "#057A55",
                height: "90%",
                color: "#ffff",
                position: "relative",
              }}
            >
              <Card.Body className="card-content">
                <Card.Title className="card-title text-center">
                  <h5 style={{ fontWeight: "700", paddingTop: "20px" }}>
                    Pengumuman Seleksi Tahap I
                  </h5>
                </Card.Title>
                <h6
                  className="d-flex justify-content-center text-center"
                  style={{ paddingTop: "30px" }}
                >
                  26 April 2024
                </h6>
                <div className="text-center mt-3">
                  {/* {setting.result === "1" ? (
                    <Link
                      to="/hasil"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <button className="button-pengumuman">
                        Sudah dibuka
                      </button>
                    </Link>
                  ) : (
                    <button className="button-pengumuman-disabled" disabled>
                      Belum dibuka
                    </button>
                  )} */}
                  {data.TAHAP_1 == 0 && (
                    <button
                      className="button-pengumuman-disabled"
                      disabled
                      // style={{ backgroundColor: "#ccc", color: "#555" }}
                    >
                      Belum Dibuka
                    </button>
                  )}
                  {data.TAHAP_1 === 1 && (
                    <a
                      href="https://ppdb-ma.jogjamadrasahdigital.net/hasil"
                      rel="noopener noreferrer"
                    >
                      <button
                        className="button-pengumuman"
                        // style={{ backgroundColor: "green", color: "white" }}
                      >
                        Sudah dibuka
                      </button>
                    </a>
                  )}
                  {data.TAHAP_1 == 2 && (
                    <button
                      className="button-pengumuman-disabled"
                      disabled
                      // style={{ backgroundColor: "#ccc", color: "#555" }}
                    >
                      Sudah Ditutup
                    </button>
                  )}
                </div>
                <div className="number-container d-flex justify-content-center align-items-center">
                  <span
                    style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                  >
                    2
                  </span>
                </div>
                <div className="image-pengumuman-container d-flex justify-content-center align-items-center">
                  <img
                    alt="announcement"
                    src={announcement}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card
              className="card-alur"
              style={{
                marginBottom: "50px",
                borderRadius: "10px",
                backgroundColor: "#057A55",
                height: "90%",
                color: "#ffff",
                position: "relative",
              }}
            >
              <Card.Body className="card-content">
                <Card.Title className="card-title text-center">
                  <h5 style={{ fontWeight: "700", paddingTop: "20px" }}>
                    Seleksi Tahap II <br />
                    Verifikasi & Wawancara
                  </h5>
                </Card.Title>
                <h6
                  className="d-flex justify-content-center text-center"
                  style={{ paddingTop: "40px", paddingBottom: "25px" }}
                >
                  26 - 27 April 2024 <br />
                  Di Madrasah Masing-masing
                </h6>
                <div className="number-container d-flex justify-content-center align-items-center">
                  <span
                    style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                  >
                    3
                  </span>
                </div>
                <div
                  className="image-container d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: "50%",
                    height: "150px",
                    width: "150px",
                    margin: "30px auto",
                  }}
                >
                  <img
                    alt="announcement"
                    src={interview}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Row className="d-flex justify-content-center align-items-center">
        <Col md={3}>
          <Card
            className="card-alur"
            style={{
              marginBottom: "50px",
              borderRadius: "10px",
              backgroundColor: "#057A55",
              height: "90%",
              color: "#ffff",
              position: "relative",
            }}
          >
            <Card.Body className="card-content">
              <Card.Title className="card-title text-center">
                <h5 style={{ fontWeight: "700", paddingTop: "20px" }}>
                  Pengumuman Tahap II <br /> (penentuan calon siswa JPTT 2024)
                </h5>
              </Card.Title>
              <h6
                className="d-flex justify-content-center text-center"
                style={{ paddingTop: "30px" }}
              >
                29 April 2024 <br />
                {/* Di Madrasah Masing-masing */}
              </h6>
              <div className="d-flex justify-content-center text-center mt-3">
                {/* {setting.result === "1" ? (
                  <Link
                    to="/pengumuman"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    <button className="button-pengumuman">Sudah dibuka</button>
                  </Link>
                ) : (
                  <button className="button-pengumuman-disabled" disabled>
                    Belum dibuka
                  </button>
                )} */}
                {data.TAHAP_2 == 0 && (
                  <button
                    className="button-pengumuman-disabled"
                    disabled
                    // style={{ backgroundColor: "#ccc", color: "#555" }}
                  >
                    Belum Dibuka
                  </button>
                )}
                {data.TAHAP_2 === 1 && (
                  <a
                    href="https://ppdb-ma.jogjamadrasahdigital.net/pengumuman"
                    rel="noopener noreferrer"
                  >
                    <button
                      className="button-pengumuman"
                      // style={{ backgroundColor: "green", color: "white" }}
                    >
                      Sudah dibuka
                    </button>
                  </a>
                )}
                {data.TAHAP_2 == 2 && (
                  <button
                    className="button-pengumuman-disabled"
                    disabled
                    // style={{ backgroundColor: "#ccc", color: "#555" }}
                  >
                    Sudah Ditutup
                  </button>
                )}
              </div>
              <div className="number-container d-flex justify-content-center align-items-center">
                <span
                  style={{
                    fontSize: "30px",
                    fontWeight: "bold",
                    color: "#fff",
                  }}
                >
                  4
                </span>
              </div>
              <div className="image-pengumuman-container d-flex justify-content-center align-items-center">
                <img
                  alt="announcement"
                  src={announce}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col md={3}>
          <Card
            className="card-alur"
            style={{
              marginBottom: "50px",
              borderRadius: "10px",
              backgroundColor: "#057A55",
              height: "90%",
              color: "#ffff",
              position: "relative",
            }}
          >
            <Card.Body className="card-content">
              <Card.Title className="card-title text-center">
                <h4 style={{ fontWeight: "700", paddingTop: "20px" }}>
                  Daftar Ulang
                </h4>
              </Card.Title>
              <h6
                className="d-flex justify-content-center text-center"
                style={{ paddingTop: "70px" }}
              >
                29 – 30 April 2024 <br />
                Di Madrasah Masing-masing
              </h6>
              <div className="number-container d-flex justify-content-center align-items-center">
                <span
                  style={{
                    fontSize: "30px",
                    fontWeight: "bold",
                    color: "#fff",
                  }}
                >
                  5
                </span>
              </div>
              <div className="image-container d-flex justify-content-center align-items-center">
                <img
                  alt="announcement"
                  src={announcement}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* BROSUR */}
      <img
        src={brosur}
        style={{
          display: "block",
          margin: "0 auto",
          width: "100%",
          maxWidth: 720,
          paddingTop: 100,
          paddingBottom: 80,
        }}
        alt="brosur ppdb"
      />

      {/* PDF */}
      <iframe
        title="Pelaksanaan PPDB JPPT TP 2023-2024"
        src={brosur1}
        height="500"
        width="90%"
        style={{
          display: "block",
          margin: "0 auto",
          maxWidth: 720,
        }}
      ></iframe>
      <div style={{ margin: 50 }}></div>

      {/* <a
        href="https://wa.me/6285336442570"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: "9999",
        }}
      >
        <img
          src={WhatsApp}
          alt="WhatsApp Icon"
          width="100"
          height="100"
          style={{ borderRadius: "40%", boxShadow: "0px 0px 20px #25D366" }}
        />
      </a> */}
      <a className="wa-btn" href="https://wa.me/62811356679" target="_blank">
        <FontAwesomeIcon className="icon" icon={faWhatsapp} />
        {/* <span>Posko Aduan</span> */}
      </a>
    </div>
  );
}

export default Home;
