import React, { useState, useEffect } from 'react';
import logo from './img/logo.png';

import axios from 'axios';

import QRCode from 'qrcode.react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const idmonths = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

const formatDate = (t) => {
	const d = new Date(t);
	const dd = d.getDate();



	const dm = idmonths[d.getMonth()];
	const dy = d.getFullYear();

	return `${dd} ${dm} ${dy}`
}
function Result(props) {


	const [data, setData] = useState({});

	const [error, setError] = useState(false);

	const [show, setShow] = useState(false);

	const [summary, setSummary] = useState({});

	const getSummary = () => {


		axios.get('/api/admin/hasil/dashboard')
			.then(function (response) {
				let x = response.data;
				setSummary(x);
			})
			.catch(function (error) {
				// handle error
				// console.log(error);
			})
			.then(function () {
				// always executed
			});


	};

	const doLoad = (id) => {

		axios.get('/api/result', { params: { id: id } })
			.then(function (response) {

				setData(response.data);
			})
			.catch(function (error) {
				// handle error
				setError(error);



			})
			.then(function () {
				// always executed


			});


	}

	useEffect(() => {
		getSummary();
		doLoad(props.match.params.id);

	}, [props.match.params.id]);


	if (error !== false) {

		return <div style={{ textAlign: 'center', background: '#eee', padding: 10 }} >
			<div className="container">

				<h1>Data tidak ditemukan</h1>

			</div>
		</div>

	}


	if (!data.code) {

		return <div style={{ margin: 10 }}><div className="spinner"><div className="bounce1"></div><div className="bounce2"></div><div className="bounce3"></div></div></div>

	}
	const showRegForm = show || (props.history.location.state && props.history.location.state.success);



	return (
		<div className="">

			{props.history.location.state && props.history.location.state.success && <div className="no-print" style={{ textAlign: 'center', background: '#eee', padding: 10 }} >
				<div className="container">

					<h1>Pendaftaran Berhasil</h1>

				</div>
			</div>}

			{!(props.history.location.state && props.history.location.state.success) && <><div className="no-print" style={{ textAlign: 'center', background: '#eee', padding: 10 }} >
				<div className="container">

					<div style={{ fontSize: '32px', fontWeight: 'bold', padding: '10px 0' }}>PORTAL</div>
					<div style={{ fontSize: '24px', fontWeight: 'normal', padding: '10px 0' }}>Hasil Seleksi Tahap I PPDB JPTT 2024</div>

				</div>
			</div>

				<div className="no-print" style={{ margin: 50 }}></div>

				<div className="container no-print" style={{ textAlign: 'center', padding: 2 }}>
					<Grid container direction="column"
						justifyContent="center"
						alignItems="center" style={{ paddingBottom: '30px' }}>
						<div style={{ fontWeight: "bold", fontSize: '32px' }}>{data.nama}</div>
						<div style={{ fontWeight: "bold", fontSize: '16px', margin: '8' }} > NISN: {data.nisn}</div>
					</Grid>

					{data.verified == -1 && <h3 style={{ color: 'darkred', margin: 10 }}>Tidak Lulus Verifikasi</h3>}

					{!data.verified && <h3 style={{ color: '#404040', margin: 10 }}>Menunggu Verifikasi Berkas</h3>}

					{!data.result && <Grid container spacing={3} style={{ textAlign: 'center' }} justify="center" alignItems="stretch">
						<Grid container direction="column"
							justifyContent="center"
							alignItems="center" style={{ display: 'flex' }} ><div style={{ fontWeight: 'normal', fontSize: '16px', padding: '16px' }}>Proses seleksi PPDB JPTT <div style={{ display: 'inline', color: 'brown', fontWeight: 'bold' }}> Sementara </div>Anda berada di</div>
							<div style={{ color: '#1E6887', padding: 15, fontWeight: 'bold', backgroundColor: '#eee', margin: 10, borderRadius: 20, boxShadow: '-3px 14px 9px -8px rgba(0,0,0,0.54)' }}>Pilihan  {data.no} : {data.name}</div>
							<div style={{ padding: 16, fontStyle: 'italic' }}>Pembaruan Terakhir {summary.t}</div>
						</Grid>

						{data.pilihan.map((i) => <Grid item xs={12} sm={6} md={4} lg={3} style={{ display: 'flex' }} >
							<div style={{ flex: 1, border: '1px solid #eee', display: 'flex', flexDirection: 'column' }}>
								<h3 style={{ color: '#1E6887', padding: 10, fontWeight: 'bold', backgroundColor: '#eee', margin: 0 }}>Pilihan {i.no}</h3>

								<div style={{ flex: 1 }}><h4 style={{ marginBottom: 0 }} >{i.sekolah} </h4>
									<div style={{}}>{i.jurusan}</div>
								</div>

								{i.pos && <div style={{ padding: 10, fontSize: '110%', color: '#1E6887' }}>Urutan ke #{i.pos}</div>}
							</div>

						</Grid>)}
					</Grid>}

					{data.result && <div>
						{data.result.success && (
                    <h2 style={{ color: "#008000" }}>
						Selamat! Anda{" "}
						{data.is_cadangan === "1"
						? "lolos seleksi tahap I Sebagai Cadangan. Apabila lanjut akan dihubungi madrasah terkait"
						: `lolos seleksi tahap I PPDB JPTT 2024 di ${data.result.sekolah}.`}
					</h2>
					)}
					{!data.result.success && (
					<h2 style={{}}>
						{data.is_cadangan === "1" 
						? "Selamat! Anda lolos seleksi tahap I Sebagai Cadangan PPDB JPTT 2024. Apabila lanjut akan dihubungi madrasah terkait"
						: "Maaf, Anda belum lolos seleksi tahap 1 PPDB JPTT 2024 dan Anda otomatis berhak mengikuti PPDB Jalur Prestasi Mandiri. Untuk informasi lebih lanjut silahkan menghubungi madrasah."}
						<br />
					</h2>
					)}

						{/* {data.cadangan && data.cadangan.length > 0 && <div>
							<h3>Cadangan</h3>

							{data.cadangan.map((i, idx) => <p key={idx}>&bull; {i.sekolah} ({i.jurusan}) </p>)}
			
						</div>} */}

						{(data.result.success || (data.cadangan && data.cadangan.length > 0)) && <p><a href="https://docs.google.com/spreadsheets/d/1WsnkruZikvMySsazFay7vQm6zhgM9wY1/edit?usp=sharing&ouid=107731123210521055052&rtpof=true&sd=true" target="_blank" style={{ color: "#006190" }}>Untuk verifikasi selanjutnya. silahkan menghubungi MA penerima.

							Klik disini untuk melihat infomasi MA.</a>

						</p>}

					</div>}
				</div>


				<div className="no-print" style={{ margin: 50 }}></div>

				{!showRegForm && <div className="no-print" style={{ textAlign: 'center' }}>
					<Button onClick={() => { setShow(true) }} variant="contained" style={{ backgroundColor: "#607D8B", color: 'white' }}>Tampilkan Bukti Pendaftaran</Button>
				</div>}


			</>


			}


			{
				showRegForm && <div className="container" style={{ textAlign: 'center' }}>

					<div className="print" style={{ border: '1px solid black', minWidth: '15cm', margin: 50, padding: 10 }}>

						<img className="logo" src={logo} alt="K2MA DIY" style={{ float: 'left', height: 100, width: 100 }} />
						<div style={{ float: 'right', }}>
							<QRCode style={{ float: 'right', margin: 0 }} size={96} value={data.url} />
						</div>

						<h3>Penerimaan Peserta Didik Baru Jalur Prestasi Tahfidz Terpadu (JPTT)
							<br />Madrasah Aliyah Se DI Yogyakarta</h3>
						<h4>Tahun Pelajaran 2024/2025</h4>


						<hr style={{ borderTop: '1px solid black' }} />

						<div style={{ float: 'right', width: '4cm', position: 'relative', height: '6cm', border: '1px dotted #c0c0c0', display: 'flex', overflow: 'hidden', alignItems: 'center' }}>
							<div style={{ position: 'absolute', top: '50%', width: '100%', textAlign: 'center', color: '#808080', zIndex: -1, }}>Foto</div>
							<div><img src={data.photo} width="100%" alt="" /></div>
						</div>
						<table style={{ margin: 10, fontSize: '14pt', textAlign: 'start', verticalAlign: 'top' }} cellSpacing={10}>
							<tbody>
								{/* <tr>
								<td>Kode Pendaftaran</td>
								<td>:</td>
								<td style={{ letterSpacing: 7, fontFamily: 'monospace', fontSize: '150%' }}>{data.id}</td>
							</tr> */}
								<tr>
									<td>Tanggal/Jam </td>
									<td>:</td>
									<td>{data.t}</td>
								</tr>
								<tr>
									<td>NISN</td>
									<td>:</td>
									<td>{data.nisn}</td>
								</tr>

								<tr>
									<td>Nama Lengkap</td>
									<td>:</td>
									<td>{data.nama} ({(data.jk == 1) ? 'L' : 'P'})</td>
								</tr>
								<tr>
									<td>TTL</td>
									<td>:</td>
									<td>{data.tempat_lahir}, {formatDate(data.tanggal_lahir)} </td>
								</tr>

								<tr>
									<td>Asal Sekolah</td>
									<td>:</td>
									<td>{data.sekolah} ({data.sekolah_lulus})</td>
								</tr>
							</tbody>


						</table>
						<div style={{ clear: 'both' }}></div>
						<table style={{ margin: 10, fontSize: '14pt', textAlign: 'start', verticalAlign: 'top' }} cellSpacing={10}><tbody>
							<tr>
								<td>Pilihan:</td>

								<td></td>
							</tr>
							{data.pilihan.map((i) => <tr key={i.no}>
								<td align="right">{i.no}.</td>
								<td>{i.sekolah}</td>
							</tr>)}
						</tbody></table>
						{/* <p>
							Pindai QRCODE diatas atau buka <b><em>https://ppdb-ma.jogjamadrasahdigital.net/hasil/{data.nisn}</em></b> untuk melihat hasil.
						</p> */}
						{/* {data.verified == -1 && <h3 style={{ color: 'darkred', margin: 10 }}>Tidak Lulus Verifikasi</h3>}
						{data.verified == 1 && <h3 style={{ color: 'green', margin: 10 }}>Terverifikasi</h3>}
						{!data.verified && <h3 style={{ color: '#404040', margin: 10 }}>Belum terverifikasi</h3>} */}
					</div>

					<div className="no-print" style={{ textAlign: 'center' }}>
						<Button onClick={() => { window.print() }} variant="contained" color="primary">Cetak Bukti Pendaftaran</Button>
					</div>

				</div>
			}


		</div >




	);
}

export default Result;
