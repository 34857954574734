import React from 'react';



const C = (props) =>{

 
    
    return(

    <div style={{textAlign: 'center', paddingTop: 50}} >

        
        
        <h2 style={{fontSize: '48pt', marginBottom: 15}}>404</h2>

        <h3>Halaman tidak dapat di temukan.</h3>

        <p>Pastikan alamat pemanggilan sudah benar dan coba beberapa saat lagi.</p>

        <a  style={{padding: 8, marginTop: 10, background: '#c0c0c0',borderRadius: 10, border: 0, padding: 10, display: 'inline-block', color: 'white'}} href="/">Halaman Utama</a>
    </div>
)};

export default C

