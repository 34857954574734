import React, { useEffect, useState } from "react";
import banner from "./img/pengumuman.png";
import { Form, InputGroup } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import "./pengumuman.css";
import logo from "./img/logo.png";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Axios from "axios";

export default function Pengumuman() {
  const [school, setSchool] = useState([]);
  const [region, setRegion] = useState({});
  const [search, setSearch] = useState("")

  const listRegion = {
    1: "Kota Yogyakarta",
    2: "Kabupaten Bantul",
    3: "Kabupaten Gunung Kidul",
    4: "Kabupaten Kulon Progo",
    5: "Kabupaten Sleman",
  };

  useEffect(() => {
    async function fetchDataSchool() {
      try {
        const response = await Axios.get("/api/school");
        const schoolsByRegion = {};

        response.data.forEach((school) => {
          if (!schoolsByRegion[school.region]) {
            schoolsByRegion[school.region] = [];
          }
          schoolsByRegion[school.region].push(school);
        });

        setSchool(schoolsByRegion);
        // console.log(schoolsByRegion);
        setRegion(listRegion);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchDataSchool();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  return (
    <>
      <div style={{ position: "relative", width: "100%", height: "auto" }}>
        <img
          className="banner-pengumuman"
          src={banner}
          alt="banner"
          style={{ width: "100%", height: "auto" }}
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: "white",
            width: "80%",
            maxWidth: "500px",
          }}
        >
          <p className="title-pengumuman">PENGUMUMAN</p>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <InputGroup
          className="mb-3"
          style={{
            width: "60%",
            paddingTop: "30px",
          }}
        >
          <InputGroup.Text
            id="basic-addon1"
            style={{ borderRadius: "90px", backgroundColor: "#f0f0f0" }}
          >
            <FaSearch />
          </InputGroup.Text>
          <Form.Control
            placeholder="Cari Sekolah"
            aria-label="Cari Sekolah"
            aria-describedby="basic-addon1"
            style={{ borderRadius: "90px", backgroundColor: "#f0f0f0" }}
            value={search}
            onChange={handleSearch}
          />
        </InputGroup>
      </div>

      <div style={{ paddingTop: "50px" }}>
        <div
          style={{
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 40,
            display: "inline-flex",
            padding: "10px 20px",
          }}
        >
          {Object.keys(school).map((regionId) => (
            <div key={regionId}>
              <div className="kab-kota">
                <div className="line"></div>
                <div className="name-kota">{region[regionId]}</div>
              </div>
              <div className="list-school">
                {school[regionId]
                  .filter((school) =>
                    school.n.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((school) => (
                    <div className="school-item" key={school.id}>
                      <Link
                        to={{
                          pathname: `/pengumuman/${school.id}`,
                          state: { schoolName: school.n }, 
                        }}
                        style={{ color: "inherit", textDecoration: "none" }}
                      >
                        <div className="school">
                          <img className="logo" src={logo} alt="logo" />
                          <div className="name-school">{school.n}</div>
                        </div>
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div style={{ margin: 50 }}></div>
    </>
  );
}
