import React, { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import baseColor from "@material-ui/core/colors/blueGrey";
import axios from "axios";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import Home from "./Home2";
import logo from "./img/logo.png";
import Error404 from "./Error404";
import Result from "./Result";
import ResultPre from "./ResultPre";
import Enroll from "./Enroll";
import DashboardHasil2 from "./DashboardHasil2";
import Sekolah2 from "./Sekolah2";
import Result2 from "./Result2";
import Result2Pre from "./Result2Pre";
import Daftar from "./Daftar";
import ReactGA from "react-ga";
import {
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Nav,
  Navbar,
} from "react-bootstrap";
import { Footer } from "Footer";
import Pengumuman from "./Pengumuman";
import LoginPage from "Login";
import School from "School";
import UbahPassword from "./UbahPassword";
import "./home2.css";

export const history = createBrowserHistory();
// const history = useHistory();
ReactGA.initialize("UA-169300522-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const Admin = React.lazy(() => import("./Admin"));
const Enroll2 = React.lazy(() => import("./Enroll2"));

let theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: baseColor,
  },
});

history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });

  ReactGA.pageview(location.pathname);
  ReactGA.pageview(location.pathname, ["gsTracker"]);
});

function App() {
  const handleLogout = (e) => {
    e.preventDefault();
    axios
      .post("/api/logout")
      .then((response) => {
        // Redirect ke halaman login setelah logout berhasil
        localStorage.removeItem("isLoggedIn");
        // Hapus item 'sekolah_id' dari localStorage
        localStorage.removeItem("sekolah_id");
        window.location.reload();
      })
      .catch((error) => {
        // Tangani kesalahan jika diperlukan
        console.error("Gagal logout:", error);
      });
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Router history={history}>
        {/* <header className="header">
          <div>
            <a href="/">
              <img className="logo" src={logo} alt="K2MA DIY" />
            </a>
          </div>
          <div className="brand">
            <a
              href="/"
              style={{
                color: "white",
                fontFamily: "Nunito, sans-serif",
                fontSize: "26px",
              }}
            >
              PPDB MADRASAH DIY
            </a>
          </div>
          <div style={{ flex: 1 }}></div>
        </header> */}
        <Navbar
          collapseOnSelect
          expand="sm"
          // className="bg-success"
          style={{ backgroundColor: "#046C4E", padding: "10px 20px" }}
        >
          <Navbar.Brand href="/" style={{ color: "#fff" }}>
            <img
              className="logo"
              src={logo}
              alt="K2MA DIY"
              style={{ marginRight: "10px" }}
            />{" "}
            PPDB MADRASAH DIY
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link href="/" style={{ color: "#fff" }}>
                Beranda
              </Nav.Link>
              <Nav.Link href="#alur-pendaftaran" style={{ color: "#fff" }}>
                Waktu Pendaftaran
              </Nav.Link>
              <Nav.Link href="" style={{ color: "#fff" }}>
                Cara Pendaftaran
              </Nav.Link>
              <Nav.Link href="#footer" style={{ color: "#fff" }}>
                Hubungi Kami
              </Nav.Link>
              <Nav.Link href="/pengumuman" style={{ color: "#fff" }}>
                Pengumuman
              </Nav.Link>
              {localStorage.getItem("isLoggedIn") === "true" && (
                <DropdownButton
                  as={ButtonGroup}
                  align={{ lg: "end" }}
                  id="dropdown-menu-align-responsive-1"
                  style={{ backgroundColor: "#046C4E" }}
                  // title="Dropdown button"
                  variant="success"
                >
                  <Dropdown.Item
                    href="/ubahpassword"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    Ubah Password
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                </DropdownButton>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <Suspense
          fallback={
            <div style={{ margin: 50 }}>
              <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            </div>
          }
        >
          <Switch>
            <Route exact path="/daftar/" component={Daftar} />
            <Route exact path="/daftar/individu/" component={Enroll} />
            <Route exact path="/daftar/kolektif/" component={Enroll2} />
            <Route exact path="/hasil/" component={ResultPre} />
            <Route exact path="/hasil/:id/" component={Result} />
            <Route exact path="/result/:id" component={Result2} />
            <Route exact path="/result/" component={Result2Pre} />
            <Route exact path="/ppdb-hasil/" component={DashboardHasil2} />
            <Route
              exact
              path="/ppdb-hasil/s/:id/"
              render={(p) => <Sekolah2 {...p} hasil={true} />}
            />
            <Route path="/ppdb-admin/" component={Admin} />
            <Route exact path="/" component={Home} />
            <Route exact path="/login/" component={LoginPage} />
            <Route exact path="/pengumuman" component={Pengumuman} />
            <Route exact path="/pengumuman/:id" component={School} />
            <Route exact path="/ubahpassword" component={UbahPassword} />
            <Route path="*">
              <Error404 />
            </Route>
          </Switch>
        </Suspense>

        {/* <footer
          style={{
            textAlign: "center",
            color: "gray",
            padding: 0,
            marginTop: 50,
            fontSize: "90%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>2023 &copy; MADRASAH DIY. Didukung oleh </div>
          <img
            style={{ width: 20, height: 20, borderRadius: "100%", margin: 5 }}
            src="https://assets.geschool.id/publics/school/student/apple-icon-57x57.png"
          />
          <div style={{ color: "#2196f3" }}>Geschool</div>
        </footer> */}
        <Footer />
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
