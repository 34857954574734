import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Typography, Container } from '@material-ui/core';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function LoginPage() {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Email:', email);
    console.log('Password:', password);
  };

  const handleSignIn = () => {
    axios.post('/api/login', { email, password }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
        console.log('Login berhasil:', response.data);
        const { sekolah_id } = response.data; // Ambil sekolah_id dari respons
        localStorage.setItem('isLoggedIn', true);
        localStorage.setItem('sekolah_id', sekolah_id);
        if (sekolah_id == 0) {
          history.push(`/ppdb-admin/`);
        } else {
          history.push(`/ppdb-admin/s/${sekolah_id}`); 
        }
        alert('Login berhasil!');
        window.location.reload();
      })
      .catch(error => {
        console.error('Login gagal:', error);
        alert('Login gagal!');
      });
  };
  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    const storedSchoolId = localStorage.getItem('sekolah_id');
    if (isLoggedIn === 'true') {
      if (storedSchoolId === '0') {
        history.push(`/ppdb-admin/`);
      } else {
        history.push(`/ppdb-admin/s/${storedSchoolId}`);
      }
    }
  }, []);
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.formContainer}>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Username/Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submitButton}
            onClick={handleSignIn}
          >
            Sign In
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default LoginPage;
