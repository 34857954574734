import React from "react";
import "./footer.css";
import logo from "./img/logo.png";
import { Link } from "react-router-dom/cjs/react-router-dom";

export const Footer = () => {
  return (
    <>
      <footer id="footer" className="site-footer">
        <div className="container d-flex justify-content-center align-items-center">
          <p className="info-text">Informasi & Kontak Kanwil Kemenag DIY</p>
        </div>
        <hr />
        <div className="">
          <div className="row">
            <div className="col-sm-12 col-md-3 d-flex justify-content-center align-items-center">
              <img src={logo} alt="" style={{ width: "30%", height: "auto" }} />
            </div>
            <div className="col-xs-6 col-md-5">
              <h6 className="text">
                Kantor Wilayah Kementrian Agama <br />
                Daerah Istimewa Yogyakarta
              </h6>
              <div className="contact">
                Jl. Sukonandi No.8, Semaki, Kec. Umbulharjo, <br /> Kota
                Yogyakarta, Daerah Istimewa Yogyakarta, <br />
                55166
              </div>
            </div>
            <div className="col-xs-6 col-md-4">
              <h6>CONTACT</h6>
              <ul className="footer-links">
                <li>
                  <Link to="/" target="_blank">
                    Telp. 0274-513492{" "}
                  </Link>
                  <Link to="/" target="_blank">
                    Fax. 0274-516030
                  </Link>
                </li>
                <li>
                  {/* <Link to="/" target="_blank">
                    0877 3953 2870
                  </Link>{" "}
                  /{" "}
                  <Link to="/" target="_blank">
                    0812 2626 4038
                  </Link>
                </li>
                <li> */}
                  <Link to="/" target="_blank">
                    kanwildiy@kemenag.go.id
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright">
        <div className="container d-flex justify-content-center align-items-center">
          <p className="copyright-text">© 2024 Copyright: Kanwil Kemenag DIY</p>
        </div>
      </div>
    </>
  );
};
